import React, { useState, useEffect } from 'react';
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import { useLocation ,useNavigate} from 'react-router-dom';
import axios from 'axios';
import common_url from '../../http_common';


function EventTicket() {
  const nav = useNavigate()

  const { state } = useLocation();
  const [events, setEvents] = useState([]);
  const [eventTicket, setEventTicket] = useState([]);
  const token = localStorage.getItem('token');


  console.log(state, 'eventstate')
  const id = state._id

  useEffect(() => {
    getEvents();
  }, []);
  const getEvents = async () => {
    await axios({
      'method': 'GET',
      'url': common_url+'event/getEventTicket/' + id,
    })
      .then(response => {
        console.log(response.data.message, 'eve')
        setEvents(response.data.message.eventDetails);
        setEventTicket(response.data.message.eventTicket);
      })
      .catch((error) => {
        alert(error)
      }
      )
  }
  // function eventDetails(e, x) {
  //   nav('/eventChekout', { state: x })
  // }
//   async function order(e,x) {
//     e.preventDefault();
//     const token = localStorage.getItem('token');
//     console.log(token,'token')
//     console.log(x,'x')
//     const amount = x.ticketPrice;

//     let payload = { events, x,amount }
//     console.log(payload, 'pcart')
//     if (token != undefined || token != null) {

//         let result = await fetch(common_url+'event/createOrderIdForEvent',
//             {
//                 method: "POST",
//                 headers: {
//                     'Authorization': token,
//                     'Content-Type': 'application/json'
//                 },
//                 body: JSON.stringify(payload)
//             })
//         const res = await result.json()
//         console.log(res.message, 'checkout')
//         // const details=res.message;
//         // console.log(details,'sdfdsdfsdf')

//         if (result.status == 200) {
//             // console.log(totalPrice,'totalp')
//             nav('/eventChekout')
//         }
//     }
  
// }
async function order(e,TicketDetails) {
  e.preventDefault();
  const token = localStorage.getItem('token');
  console.log(token,'token')
  console.log(TicketDetails,'x')
  const amount = TicketDetails.ticketPrice;

  let payload = { events, TicketDetails,amount }
  console.log(payload, 'pcart')
  if (token != undefined || token != null) {

      let result = await fetch(common_url+'event/eventBooking',
          {
              method: "POST",
              headers: {
                  'Authorization': token,
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(payload)
          })
      const res = await result.json()
      console.log(res.message, 'checkout')
      const details=res.message;
      console.log(details,'sdfdsdfsdf')

      if (result.status == 200) {
          // console.log(totalPrice,'totalp')
          nav('/eventChekout', { state: details })
      }
  }

}


  return (
    <div>
      <Header />
      <div>
        <section class="details-banner event-details-banner hero-area bg_img seat-plan-banner" data-background="./assets/images/banner/banner07.jpg">
          <div class="container">
            <div class="details-banner-wrapper">
              <div class="details-banner-content style-two">
                <h3 class="title"><span class="d-block">{events.eventName}</span>
                  {/* <span class="d-block">Conference -2020</span> */}
                </h3>
                <div class="tags">
                  <span>{events.address}</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="page-title bg-one">
          <div class="container">
            <div class="page-title-area">
              <div class="item md-order-1">
                <a href="#0" class="custom-button back-button">
                  <i class="flaticon-double-right-arrows-angles"></i>back
                </a>
              </div>
              <div class="item date-item">
                <span class="date">{events.eventDate}</span>
              </div>
              <div class="item">
                <h5 class="title">05:00</h5>
                <p>Mins Left</p>
              </div>
            </div>
          </div>
        </section>
        <div class="event-facility padding-bottom padding-top">
          <div class="container"><div class="section-header-3">
            <span class="cate">simple pricing</span>
            <h2 class="title">make an appointment</h2>
            <p>{events.description}</p>
          </div>
            <div class="row justify-content-center mb-30-none">
              {eventTicket.map(TicketDetails =>
                <div class="col-md-6 col-lg-4 col-sm-10">
                  <div class="ticket--item">
                    <div class="ticket-thumb">
                      <img src="./assets/images/event/ticket/ticket01.png" alt="event" />
                    </div>
                    <div class="ticket-content">
                      <span class="ticket-title">{TicketDetails.ticketName}</span>
                      <h2 class="amount"><sup>$</sup>{TicketDetails.ticketPrice}</h2>
                      <ul>
                        <li>{TicketDetails.ticketDescription}</li>
                        <li class="del"><del>Video presentations</del></li>
                        <li class="del"><del>Meet all of our event speakers</del></li>
                      </ul>{localStorage.getItem('token')?
                      <a onClick={(e) => order(e,TicketDetails)} class="custom-button">book tickets</a>
                      :
                      <a href='/signIn' class="custom-button">book tickets</a>
}
                    </div>
                  </div>
                </div>
              )}



            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default EventTicket