import React from 'react'
import { Helmet } from 'react-helmet';
// import logo from "../../assets/images/logo/logo.png";
import logo from '../../assets/images/logo/logo.png'

function Footer() {
  return (
    <div>
      <footer class="footer-section">
        <div class="newslater-section padding-bottom">
          <div class="container">
            <div class="newslater-container bg_img" data-background="./assets/images/newslater/newslater-bg01.jpg">
              <div class="newslater-wrapper">
                <h5 class="cate">subscribe to Block your Movie </h5>
                <h3 class="title">to get exclusive benifits</h3>
                <form class="newslater-form">
                  <input type="text" placeholder="Your Email Address" />
                  <button type="submit">subscribe</button>
                </form>
                <p>We respect your privacy, so we never share your info</p>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="footer-top">
            <div class="logo">
              <a href="index-1.html">
                <img src={logo} alt="footer" style={{width: '120px'}} />
              </a>
            </div>
            <ul class="social-icons">
              <li>
                <a href="#0">
                  <i class="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="#0" class="active">
                  <i class="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#0">
                  <i class="fab fa-pinterest-p"></i>
                </a>
              </li>
              <li>
                <a href="#0">
                  <i class="fab fa-google"></i>
                </a>
              </li>
              <li>
                <a href="#0">
                  <i class="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
          <div class="footer-bottom">
            <div class="footer-bottom-area">
              <div class="left">
                <p>Copyright © 2022.All Rights Reserved By <a href="#0">Block your Movie </a></p>
              </div>
              <ul class="links">
                <li>
                  <a href="#0">About</a>
                </li>
                <li>
                  <a href="#0">Terms Of Use</a>
                </li>
                <li>
                  <a href="#0">Privacy Policy</a>
                </li>
                <li>
                  <a href="#0">FAQ</a>
                </li>
                <li>
                  <a href="#0">Feedback</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <Helmet>
        <script src="../../assets/js/jquery-3.3.1.min.js"></script>
        <script src="../../assets/js/modernizr-3.6.0.min.js"></script>
        <script src="../../assets/js/plugins.js"></script>
        <script src="../../assets/js/bootstrap.min.js"></script>
        <script src="../../assets/js/heandline.js"></script>
        <script src="../../assets/js/isotope.pkgd.min.js"></script>
        <script src="../../assets/js/magnific-popup.min.js"></script>
        <script src="../../assets/js/owl.carousel.min.js"></script>
        <script src="../../assets/js/wow.min.js"></script>
        <script src="../../assets/js/countdown.min.js"></script>
        <script src="../../assets/js/odometer.min.js"></script>
        <script src="../../assets/js/viewport.jquery.js"></script>
        <script src="../../assets/js/nice-select.js"></script>
        <script src="../../assets/js/main.js"></script>
      </Helmet>
    </div>
  )
}

export default Footer