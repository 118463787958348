import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import TicketTab1 from "../../assets/images/ticket/ticket-tab01.png";
import TicketTab2 from "../../assets/images/ticket/ticket-tab02.png";
import TicketTab3 from "../../assets/images/ticket/ticket-tab03.png";
import City from "../../assets/images/ticket/city.png";
import Date from "../../assets/images/ticket/date.png";
import Cinema from "../../assets/images/ticket/cinema.png";
import Sidebar1 from '../../assets/images/sidebar/icons/sidebar01.png';
import Movie1 from "../../assets/images/movie/movie01.jpg";
import sports3 from "../../assets/images/sports/sports03.jpg";
import sports2 from "../../assets/images/sports/sports02.jpg";
import sports1 from "../../assets/images/sports/sports01.jpg";
import event3 from "../../assets/images/event/event03.jpg";
import event2 from "../../assets/images/event/event02.jpg";
import event1 from "../../assets/images/event/event01.jpg";
import cake from "../../assets/images/movie/cake.png";
import tomato from "../../assets/images/movie/tomato.png";
import movie3 from "../../assets/images/movie/movie03.jpg";
import movie2 from "../../assets/images/movie/movie02.jpg";
import banner2 from "../../assets/images/sidebar/banner/banner02.jpg";
import banner1 from "../../assets/images/sidebar/banner/banner01.jpg";
import sidebar3 from "../../assets/images/sidebar/icons/sidebar03.png";
import sidebar2 from "../../assets/images/sidebar/icons/sidebar02.png";
import sidebar1 from "../../assets/images/sidebar/icons/sidebar01.png";
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import common_url from '../../http_common';

function Home() {
  const [movies, setMovies] = useState([]);
  const [events, setEvents] = useState([]);
  const [sports, setSports] = useState([]);
  const nav = useNavigate()

  useEffect(() => {
    getMovies();
    getSports();
    getEvents();
  }, []);


  const getMovies = () => {
    axios.get(common_url+'movie/getMovieDetails').then(res => {
       console.log(res.data.message, 'movies')
      setMovies(res.data.message);
    });
  }
  const getSports = () => {
    axios.get(common_url+'sports/getSports').then(res => {
      console.log(res.data.data, 'sports')
      setSports(res.data.data);
    });
  }
  const getEvents = () => {
    axios.get(common_url+'event/getEvent').then(res => {
      console.log(res.data.message, 'events')
      setEvents(res.data.message);
    });
  }

  function movieDetails(e, x) {
    nav('/moviedetails', { state: x })
  }
  function eventDetails(e, x) {
    nav('/eventdetial', { state: x })
  }

  function sportsDetails(e, x) {
    nav('/sportsdetail', { state: x })
}

  return (
    <div>
      <Header />
      <div class="overlay"></div>
      <a href="#0" class="scrollToTop">
        <i class="fas fa-angle-up"></i>
      </a>
      <section class="banner-section">
        <div class="banner-bg bg_img bg-fixed" style={{
          backgroundImage: "url(" + "../../assets/images/banner/banner01.jpg" + ")",
        }}>
        </div>
        <div class="container">
          <div class="banner-content">
            <h1 class="title  cd-headline clip"><span class="d-block">book your</span> tickets for 
            &nbsp;
              <span class="color-theme cd-words-wrapper p-0 m-0">
                <b class="is-visible">Movie</b>
                <b>Event</b>
                <b>Sport</b>
              </span>
            </h1>
            <p>Safe, secure, reliable ticketing.Your ticket to live entertainment!</p>
          </div>
        </div>
      </section>
      <section class="search-ticket-section padding-top pt-lg-0">
        <div class="container">
          <div class="search-tab bg_img" data-background="./assets/images/ticket/ticket-bg01.jpg">
            <div class="row align-items-center mb--20">
              <div class="col-lg-6 mb-20">
                <div class="search-ticket-header">
                  <h6 class="category">welcome to Block your Movie </h6>
                  <h3 class="title">what are you looking for</h3>
                </div>
              </div>
              <div class="col-lg-6 mb-20">
                <ul class="tab-menu ticket-tab-menu">
                  <li class="active">
                    <div class="tab-thumb">
                      <img src={TicketTab1} alt="ticket" />
                    </div>
                    <span>movie</span>
                  </li>
                  <li>
                    <div class="tab-thumb">
                      <img src={TicketTab2} alt="ticket" />
                    </div>
                    <span>events</span>
                  </li>
                  <li>
                    <div class="tab-thumb">
                      <img src={TicketTab3} alt="ticket" />
                    </div>
                    <span>sports</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="tab-area">
              <div class="tab-item active">
                <form class="ticket-search-form">
                  <div class="form-group large">
                    <input type="text" placeholder="Search for Movies" />
                    <button type="submit"><i class="fas fa-search"></i></button>
                  </div>
                  <div class="form-group">
                    <div class="thumb">
                      <img src={City} alt="ticket" />
                    </div>
                    <span class="type">city</span>
                    <select class="select-bar">
                      <option value="london">London</option>
                      <option value="dhaka">dhaka</option>
                      <option value="rosario">rosario</option>
                      <option value="madrid">madrid</option>
                      <option value="koltaka">kolkata</option>
                      <option value="rome">rome</option>
                      <option value="khoksa">khoksa</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <div class="thumb">
                      <img src={Date} alt="ticket" />
                    </div>
                    <span class="type">date</span>
                    <select class="select-bar">
                      <option value="26-12-19">23/10/2020</option>
                      <option value="26-12-19">24/10/2020</option>
                      <option value="26-12-19">25/10/2020</option>
                      <option value="26-12-19">26/10/2020</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <div class="thumb">
                      <img src={Cinema} alt="ticket" />
                    </div>
                    <span class="type">cinema</span>
                    <select class="select-bar">
                      <option value="Awaken">Awaken</option>
                      <option value="dhaka">dhaka</option>
                      <option value="rosario">rosario</option>
                      <option value="madrid">madrid</option>
                      <option value="koltaka">kolkata</option>
                      <option value="rome">rome</option>
                      <option value="khoksa">khoksa</option>
                    </select>
                  </div>
                </form>
              </div>
              <div class="tab-item">
                <form class="ticket-search-form">
                  <div class="form-group large">
                    <input type="text" placeholder="Search for Events" />
                    <button type="submit"><i class="fas fa-search"></i></button>
                  </div>
                  <div class="form-group">
                    <div class="thumb">
                      <img src={City} alt="ticket" />
                    </div>
                    <span class="type">city</span>
                    <select class="select-bar">
                      <option value="london">London</option>
                      <option value="dhaka">dhaka</option>
                      <option value="rosario">rosario</option>
                      <option value="madrid">madrid</option>
                      <option value="koltaka">kolkata</option>
                      <option value="rome">rome</option>
                      <option value="khoksa">khoksa</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <div class="thumb">
                      <img src={Date} alt="ticket" />
                    </div>
                    <span class="type">date</span>
                    <select class="select-bar">
                      <option value="26-12-19">23/10/2020</option>
                      <option value="26-12-19">24/10/2020</option>
                      <option value="26-12-19">25/10/2020</option>
                      <option value="26-12-19">26/10/2020</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <div class="thumb">
                      <img src={Cinema} alt="ticket" />
                    </div>
                    <span class="type">event</span>
                    <select class="select-bar">
                      <option value="angular">angular</option>
                      <option value="startup">startup</option>
                      <option value="rosario">rosario</option>
                      <option value="madrid">madrid</option>
                      <option value="koltaka">kolkata</option>
                      <option value="Last-First">Last-First</option>
                      <option value="wish">wish</option>
                    </select>
                  </div>
                </form>
              </div>
              <div class="tab-item">
                <form class="ticket-search-form">
                  <div class="form-group large">
                    <input type="text" placeholder="Search fo Sports" />
                    <button type="submit"><i class="fas fa-search"></i></button>
                  </div>
                  <div class="form-group">
                    <div class="thumb">
                      <img src={City} alt="ticket" />
                    </div>
                    <span class="type">city</span>
                    <select class="select-bar">
                      <option value="london">London</option>
                      <option value="dhaka">dhaka</option>
                      <option value="rosario">rosario</option>
                      <option value="madrid">madrid</option>
                      <option value="koltaka">kolkata</option>
                      <option value="rome">rome</option>
                      <option value="khoksa">khoksa</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <div class="thumb">
                      <img src={Date} alt="ticket" />
                    </div>
                    <span class="type">date</span>
                    <select class="select-bar">
                      <option value="26-12-19">23/10/2020</option>
                      <option value="26-12-19">24/10/2020</option>
                      <option value="26-12-19">25/10/2020</option>
                      <option value="26-12-19">26/10/2020</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <div class="thumb">
                      <img src={Cinema} alt="ticket" />
                    </div>
                    <span class="type">sports</span>
                    <select class="select-bar">
                      <option value="football">football</option>
                      <option value="cricket">cricket</option>
                      <option value="cabadi">cabadi</option>
                      <option value="madrid">madrid</option>
                      <option value="gadon">gadon</option>
                      <option value="rome">rome</option>
                      <option value="khoksa">khoksa</option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="movie-section padding-top padding-bottom bg-two">
        <div class="container">
          <div class="row flex-wrap-reverse justify-content-center">
            <div class="col-lg-3 col-sm-10  mt-50 mt-lg-0">
              <div class="widget-1 widget-facility">
                <div class="widget-1-body">
                  <ul>
                    <li>
                      <a href="#0">
                        <span class="img"><img src={sidebar1} alt="sidebar" /></span>
                        <span class="cate">24X7 Care</span>
                      </a>
                    </li>
                    <li>
                      <a href="#0">
                        <span class="img"><img src={sidebar2} alt="sidebar" /></span>
                        <span class="cate">100% Assurance</span>
                      </a>
                    </li>
                    <li>
                      <a href="#0">
                        <span class="img"><img src={sidebar3} alt="sidebar" /></span>
                        <span class="cate">Our Promise</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="widget-1 widget-banner">
                <div class="widget-1-body">
                  <a href="#0">
                    <img src={banner1} alt="banner" />
                  </a>
                </div>
              </div>
              <div class="widget-1 widget-trending-search">
                <h3 class="title">Trending Searches</h3>
                <div class="widget-1-body">
                  <ul>
                    <li>
                      <h6 class="sub-title">
                        <a href="#0">mars</a>
                      </h6>
                      <p>Movies</p>
                    </li>
                    <li>
                      <h6 class="sub-title">
                        <a href="#0">alone</a>
                      </h6>
                      <p>Movies</p>
                    </li>
                    <li>
                      <h6 class="sub-title">
                        <a href="#0">music event</a>
                      </h6>
                      <p>event</p>
                    </li>
                    <li>
                      <h6 class="sub-title">
                        <a href="#0">NBA Games 2020</a>
                      </h6>
                      <p>Sports</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="widget-1 widget-banner">
                <div class="widget-1-body">
                  <a href="#0">
                    <img src={banner2} alt="banner" />
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-9">
              <div class="article-section padding-bottom">
                <div class="section-header-1">
                  <h2 class="title">movies</h2>
                  <a class="view-all" href="/movie">View All</a>
                </div>
                <div class="row mb-30-none justify-content-center">
                  {movies.map(x =>

                    <div class="col-sm-6 col-lg-4">
                      <div class="movie-grid">
                        <div class="movie-thumb c-thumb">
                          {/* <a href=""> */}
                            <img src={x.movieImage} onClick={(e) => movieDetails(e, x)}  alt="movie" />
                          {/* </a> */}
                        </div>
                        <div class="movie-content bg-one">
                          <h5 class="title m-0">
                            <a href="#0">{x.movieName}</a>
                          </h5>
                          <ul class="movie-rating-percent">
                            <li>
                              <div class="thumb">
                                <img src={tomato} alt="movie" />
                              </div>
                              <span class="content">{x.rating}%</span>
                            </li>
                            <li>
                              <div class="thumb">
                                <img src={cake} alt="movie" />
                              </div>
                              <span class="content">{x.reviewCount}%</span>
                            </li>
                          </ul>
                        </div>
                      </div>

                    </div>
                  )}

                </div>
              </div>
              <div class="article-section padding-bottom">
                <div class="section-header-1">
                  <h2 class="title">events</h2>
                  <a class="view-all" href="events">View All</a>
                </div>
                <div class="row mb-30-none justify-content-center">
                  {events.map(x =>
                    <div class="col-sm-6 col-lg-4">
                      <div class="event-grid">
                        <div class="movie-thumb c-thumb">
                        
                            <img src={x.eventImage} onClick={(e) => eventDetails(e, x)} alt="event" />
                        
                          <div class="event-date">
                            {/* <h6 class="date-title">28</h6> */}
                            <span>{x.eventDate}</span>
                          </div>
                        </div>
                        <div class="movie-content bg-one">
                          <h5 class="title m-0">
                            <a href="#0">{x.eventName}</a>
                          </h5>
                          <div class="movie-rating-percent">
                            <span>{x.location}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}


                </div>
              </div>
              <div class="article-section">
                <div class="section-header-1">
                  <h2 class="title">sports</h2>
                  <a class="view-all" href="sports">View All</a>
                </div>
                <div class="row mb-30-none justify-content-center">
                  {sports.map(x =>
                    <div class="col-sm-6 col-lg-4">
                      <div class="sports-grid">
                        <div class="movie-thumb c-thumb">
                          {/* <a href="#0"> */}
                            <img src={x.sportsImage} alt="sports" onClick={(e) => sportsDetails(e, x)} />
                           {/* </a> */}
                          <div class="event-date">
                            {/* <h6 class="date-title">{x.sportsTime}</h6> */}
                            <span>{x.sportsTime}</span>
                          </div>
                        </div>
                        <div class="movie-content bg-one">
                          <h5 class="title m-0">
                            <a href="#0">{x.sportsName}</a>
                          </h5>
                          <div class="movie-rating-percent">
                            <span>{x.location}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />


      <Helmet>
        <script src="../../assets/js/jquery-3.3.1.min.js"></script>
        <script src="../../assets/js/modernizr-3.6.0.min.js"></script>
        <script src="../../assets/js/plugins.js"></script>
        <script src="../../assets/js/bootstrap.min.js"></script>
        <script src="../../assets/js/heandline.js"></script>
        <script src="../../assets/js/isotope.pkgd.min.js"></script>
        <script src="../../assets/js/magnific-popup.min.js"></script>
        <script src="../../assets/js/owl.carousel.min.js"></script>
        <script src="../../assets/js/wow.min.js"></script>
        <script src="../../assets/js/countdown.min.js"></script>
        <script src="../../assets/js/odometer.min.js"></script>
        <script src="../../assets/js/viewport.jquery.js"></script>
        <script src="../../assets/js/nice-select.js"></script>
        <script src="../../assets/js/main.js"></script>
      </Helmet>
    </div >
  )
}

export default Home