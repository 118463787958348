import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import common_url from '../../http_common';
function SportsDetails() {
  
  const { state } = useLocation();
  // console.log(state,'state')
  const id = state._id

  const [sports, setSports] = useState([]);
  const nav = useNavigate()

  useEffect(() => {
    getSports();
  }, []);
  const getSports = async () => {
    await axios({
      'method': 'GET',
      'url': common_url+'sports/getSingleSports/' + id,
    })
      .then(response => {
        console.log(response.data.data[0], 'sports')
        setSports(response.data.data[0]);
      })
      .catch((error) => {
        alert(error)
      }
      )
  }
  
  function sportsDetails(e, x) {
    nav('/sportsticket', { state: sports })
  }
  return (
    <div>
      <Header />
      <section class="event-banner-section bg_img" data-background="./assets/images/banner/banner09.jpg">
        <div class="container">
          <div class="event-banner">
            <a href="https://www.youtube.com/embed/GT6-H4BRyqQ" class="video-popup">
              <span></span>
              <i class="flaticon-play-button"></i>
            </a>
          </div>
        </div>
      </section>
      <section class="event-book-search padding-top pt-lg-0">
        <div class="container">
          <div class="event-search bg_img" data-background="./assets/images/ticket/ticket-bg01.jpg">
            <div class="event-search-top">
              <div class="left">
                <h3 class="title">{sports.sportsName}</h3>
              </div>
              <div class="right">
                <ul class="countdown">
                  <li>
                    <h2><span class="days">00</span></h2>
                    <p class="days_text">days</p>
                  </li>
                  <li>
                    <h2><span class="hours">00</span></h2>
                    <p class="hours_text">hrs</p>
                  </li>
                  <li>
                    <h2><span class="minutes">00</span></h2>
                    <p class="minu_text">min</p>
                  </li>
                  <li>
                    <h2><span class="seconds">00</span></h2>
                    <p class="seco_text">sec</p>
                  </li>
                </ul>
                <a  onClick={(e) => sportsDetails(e, sports)} class="custom-button">book tickets</a>
              </div>
            </div>
            <div class="event-search-bottom">
              <div class="contact-side justify-content-start">
                <div class="item">
                  <div class="item-thumb">
                    <img src="./assets/images/event/icon/event-icon02.png" alt="event"/>
                  </div>
                  <div class="item-content">
                    <span class="up">{sports.address}</span>
                    {/* <span>Astoria, NY 11106</span> */}
                  </div>
                </div>
                <div class="item">
                  <div class="item-thumb">
                    <img src="./assets/images/event/icon/event-icon03.png" alt="event"/>
                  </div>
                  <div class="item-content">
                    <span class="up">Drop us a line:</span>
                    <a href="MailTo:hello@Boleto.com"><span class="__cf_email__" data-cfemail="79111c151516393b16151c0d16571a1614">[email&#160;protected]</span></a>
                  </div>
                </div>
              </div>
              <ul class="social-icons">
                <li>
                  <a href="#0">
                    <i class="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="#0" class="">
                    <i class="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#0" class="active">
                    <i class="fab fa-pinterest-p"></i>
                  </a>
                </li>
                <li>
                  <a href="#0" class="">
                    <i class="fab fa-google"></i>
                  </a>
                </li>
                <li>
                  <a href="#0">
                    <i class="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section class="event-about padding-top padding-bottom">
        <div class="container">
          <div class="row justify-content-between flex-wrap-reverse">
            <div class="col-lg-7 col-xl-6">
              <div class="event-about-content">
                <div class="section-header-3 left-style m-0">
                  <span class="cate">are you ready to attend?</span>
                  <h2 class="title">{sports.sportsName}</h2>
                  <p>{sports.description}   </p>
                  <a onClick={(e) => sportsDetails(e, sports)} class="custom-button">book tickets</a>
                </div>
              </div>
            </div>
            <div class="col-lg-5 col-md-7">
              <div class="event-about-thumb">
                <img src="./assets/images/sports/sports-about.jpg" alt="sports"/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default SportsDetails