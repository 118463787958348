import React, { useState, useEffect } from 'react';

import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';

import common_url from '../../http_common';


function Events() {
  const [events, setEvents] = useState([]);
  const nav = useNavigate()
  const [city, setCity] = useState('');
  const [cityFilter, setCityFilter] = useState([]);

  useEffect(() => {
    getEvents();
  }, []);
  const getEvents = () => {
    axios.get(common_url+'event/getEvent').then(res => {
      // console.log(res.data.message, 'resevents')
      setEvents(res.data.message);
    });
  }

  function eventDetails(e, x) {
    nav('/eventdetial', { state: x })
  }
  async function GetLocation() {
    await axios({
      'method': 'GET',
      'url': common_url+'event/searchForEvent/' + city,
    })
      .then((res) => {
        console.log(res.data.message, 'getcity')
        setCityFilter(res.data.message)
      }).catch((err) => {
        console.log("ERR =>", err);
      })
  }
  return (


    <div>
      <Header />
      <div>
        <section class="banner-section">
          <div class="banner-bg bg_img" data-background="./assets/images/banner/banner05.jpg"></div>
          <div class="container">
            <div class="banner-content event-content">
              <h1 class="title bold">get <span class="color-theme">events</span> tickets</h1>
              <p>Buy event tickets in advance, find event times and much more</p>
            </div>
          </div>
        </section>
        <section class="search-ticket-section padding-top pt-lg-0">
          <div class="container">
            <div class="search-tab bg_img" data-background="./assets/images/ticket/ticket-bg01.jpg">
              <div class="row align-items-center mb--20">
                <div class="col-lg-6 mb-20">
                  <div class="search-ticket-header">
                    <h6 class="category">welcome to Boleto </h6>
                    <h3 class="title">what are you looking for</h3>
                  </div>
                </div>
                <div class="col-lg-6 mb-20">
                  <ul class=" ticket-tab-menu">
                    <li class="active">
                      <Link to='/movie'>
                        <div class="tab-thumb">
                          <img src="./assets/images/ticket/ticket-tab01.png" alt="ticket" />
                        </div>
                        <span>movie</span>
                      </Link>
                    </li>
                    <li>
                      <Link to='/events'>
                        <div class="tab-thumb">
                          <img src="./assets/images/ticket/ticket-tab02.png" alt="ticket" />
                        </div>
                        <span>events</span>
                      </Link>
                    </li>
                    <li>
                      <Link to='/sports'>
                        <div class="tab-thumb">
                          <img src="./assets/images/ticket/ticket-tab03.png" alt="ticket" />
                        </div>
                        <span>sports</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="tab-area">
                <div class="tab-item active">
                  <form class="ticket-search-form">
                    <div class="row form-group large">
                      <div class="col-lg-6">
                         <input type="text" placeholder="Search for City" onChange={(e) => setCity(e.target.value)} />
                      </div>
                      <div class="col-lg-6">
                        <button type="submit"><i class="fas fa-search"></i></button>
                        <span>
                          <a class=" search-btn" onClick={(e) => {
                            GetLocation(e)
                          }}> search</a>
                        </span>
                        </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="event-section padding-top padding-bottom">
          <div class="container">
            <div class="row flex-wrap-reverse justify-content-center">
              <div class="col-sm-10 col-md-8 col-lg-3">
                <div class="widget-1 widget-banner">
                  <div class="widget-1-body">
                    <a href="#0">
                      <img src="./assets/images/sidebar/banner/banner01.jpg" alt="banner" />
                    </a>
                  </div>
                </div>
                <div class="widget-1 widget-check">
                  <div class="widget-header">
                    <h5 class="m-title">Filter By</h5> <a href="#0" class="clear-check">Clear All</a>
                  </div>
                  <div class="widget-1-body">
                    <h6 class="subtitle">categories</h6>
                    <div class="check-area">
                      <div class="form-group">
                        <input type="checkbox" name="lang" id="cat1" /><label for="cat1">all</label>
                      </div>
                      <div class="form-group">
                        <input type="checkbox" name="lang" id="cat2" /><label for="cat2">Screening</label>
                      </div>
                      <div class="form-group">
                        <input type="checkbox" name="lang" id="cat3" /><label for="cat3">meetings</label>
                      </div>
                      <div class="form-group">
                        <input type="checkbox" name="lang" id="cat4" /><label for="cat4">Performances</label>
                      </div>
                      <div class="form-group">
                        <input type="checkbox" name="lang" id="cat5" /><label for="cat5">workshops</label>
                      </div>
                      <div class="form-group">
                        <input type="checkbox" name="lang" id="cat6" /><label for="cat6">Exhibitions </label>
                      </div>
                      <div class="form-group">
                        <input type="checkbox" name="lang" id="cat8" /><label for="cat8">music shows</label>
                      </div>
                      <div class="form-group">
                        <input type="checkbox" name="lang" id="cat9" /><label for="cat9">comedy shows</label>
                      </div>
                      <div class="form-group">
                        <input type="checkbox" name="lang" id="cat10" /><label for="cat10">Award shows</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="widget-1 widget-banner">
                  <div class="widget-1-body">
                    <a href="#0">
                      <img src="./assets/images/sidebar/banner/banner02.jpg" alt="banner" />
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-9 mb-50 mb-lg-0">
                <div class="filter-tab">
                  <div class="filter-area">
                    <div class="filter-main">
                      <div class="left w-100 justify-content-between">
                        <div class="item">
                          <span class="show">Show :</span>
                          <select class="select-bar">
                            <option value="12">12</option>
                            <option value="15">15</option>
                            <option value="18">18</option>
                            <option value="21">21</option>
                            <option value="24">24</option>
                            <option value="27">27</option>
                            <option value="30">30</option>
                          </select>
                        </div>
                        <div class="item mr-0">
                          <span class="show">Sort By :</span>
                          <select class="select-bar">
                            <option value="showing">now showing</option>
                            <option value="exclusive">exclusive</option>
                            <option value="trending">trending</option>
                            <option value="most-view">most view</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-10 justify-content-center">
                    {cityFilter.length > 0 ?
                      cityFilter.map(x =>
                        <div class="col-sm-6 col-lg-4">
                          <div class="event-grid">
                            <div class="movie-thumb c-thumb">
                              <a onClick={(e) => eventDetails(e, x)} >
                                <img src={x.eventImage} alt="event" />
                              </a>
                              <div class="event-date">
                                {/* <h6 class="date-title">28</h6> */}
                                <span>{x.eventDate}</span>
                              </div>
                            </div>
                            <div class="movie-content bg-one">
                              <h5 class="title m-0">
                                <a onClick={(e) => eventDetails(e, x)} >{x.eventName}</a>
                              </h5>
                              <div class="movie-rating-percent">
                                <span>{x.location}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )

                      : <>
                        {events.map(x =>
                          <div class="col-sm-6 col-lg-4">
                            <div class="event-grid">
                              <div class="movie-thumb c-thumb">
                                <a onClick={(e) => eventDetails(e, x)} >
                                  <img src={x.eventImage} alt="event" />
                                </a>
                                <div class="event-date">
                                  {/* <h6 class="date-title">28</h6> */}
                                  <span>{x.eventDate}</span>
                                </div>
                              </div>
                              <div class="movie-content bg-one">
                                <h5 class="title m-0">
                                  <a onClick={(e) => eventDetails(e, x)} >{x.eventName}</a>
                                </h5>
                                <div class="movie-rating-percent">
                                  <span>{x.location}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    }


                  </div>
                  <div class="pagination-area text-center">
                    <a href="#0"><i class="fas fa-angle-double-left"></i><span>Prev</span></a>
                    <a href="#0">1</a>
                    <a href="#0">2</a>
                    <a href="#0" class="active">3</a>
                    <a href="#0">4</a>
                    <a href="#0">5</a>
                    <a href="#0"><span>Next</span><i class="fas fa-angle-double-right"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  )
}

export default Events