import React, { useState, useEffect } from 'react';
import Footer from '../Footer/Footer'
import Header from '../Header/Header';
import { useLocation,useNavigate } from 'react-router-dom';
import axios from 'axios';
import OwlCarousel from 'react-owl-carousel';
import {Link} from "react-router-dom";
import common_url from '../../http_common';


function MovieDetails() {
  // console.log(props.city,'city')
  const { state } = useLocation();
  const [movies, setMovies] = useState([]);
  const [cast, setCast] = useState([]);
  const [crew, setCrew] = useState([]);
  const [dimension, setDimenstion] = useState([]);
  const [movieType, setMovieType] = useState([]);
  const [language, setLanguage] = useState([]);
  const [review, setReview] = useState([]);

  const nav = useNavigate()

  const id = state._id

  useEffect(() => {
    getMovies();
  }, []);


  // const getMovies = () => {
  //   axios.get(common_url+'movie/getSingleMovieDetails/' + id).then(res => {
  //     console.log(res.data.message, 'resmovies')
  //     setMovies( res.data.message);
  //   });
  //     console.log(movies,'movies')
  // }
  const owlstate = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  }
  const getMovies = async () => {
    await axios({
      'method': 'GET',
      'url': common_url+'movie/getSingleMovieDetails/' + id,
    })
      .then(response => {
        console.log(response.data.message, 'resmovies')
        setMovies(response.data.message);
        setCast(response.data.message.cast);
        setCrew(response.data.message.crew);
        setDimenstion(response.data.message.dimension);
        setMovieType(response.data.message.typeOfMovie);
        setLanguage(response.data.message.language);
        setReview(response.data.message.review);
      })
      .catch((error) => {
        alert(error)
      }
      )

  }
  function MovieTicket(e, movies) {
    // console.log(movies,'movies')
    nav('/movieticketplan', { state: movies })
  }

  return (
    <div>
      <Header />
      <section class="details-banner bg_img" data-background="./assets/images/banner/banner03.jpg">
        <div class="container">
          <div class="details-banner-wrapper">
            <div class="details-banner-thumb">
              <img src={movies.movieImage} alt="movie" />
              <a href="https://www.youtube.com/embed/KGeBMAgc46E" class="video-popup">
                <img src="./assets/images/movie/video-button.png" alt="movie" />
              </a>
            </div>
            <div class="details-banner-content offset-lg-3">
              <h3 class="title">{movies.movieName}</h3>
              <div class="tags">
                {language.map(x =>
                  <a href="#0" class="button">{x}</a>
                )}
              </div>
              {movieType.map(x =>
                <a href="#0" class="button">{x}</a>
              )}

              <div class="social-and-duration">
                <div class="duration-area">
                  <div class="item">
                    <i class="fas fa-calendar-alt"></i><span>{movies.releaseDate}</span>
                  </div>
                  <div class="item">
                    <i class="far fa-clock"></i><span>{movies.duration}</span>
                  </div>
                </div>
                <ul class="social-share">
                  <li><a href="#0"><i class="fab fa-facebook-f"></i></a></li>
                  <li><a href="#0"><i class="fab fa-twitter"></i></a></li>
                  <li><a href="#0"><i class="fab fa-pinterest-p"></i></a></li>
                  <li><a href="#0"><i class="fab fa-linkedin-in"></i></a></li>
                  <li><a href="#0"><i class="fab fa-google-plus-g"></i></a></li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </section>
      <section class="book-section bg-one">
        <div class="container">
          <div class="book-wrapper offset-lg-3">
            <div class="left-side">
              <div class="item">
                <div class="item-header">
                  <div class="thumb">
                    <img src="./assets/images/movie/tomato2.png" alt="movie" />
                  </div>
                  <div class="counter-area">
                    <span class="counter-item odometer" data-odometer-final={movies.rating}>0</span>
                  </div>
                </div>
                <p>tomatometer</p>
              </div>
              <div class="item">
                <div class="item-header">
                  <div class="thumb">
                    <img src="./assets/images/movie/cake2.png" alt="movie" />
                  </div>
                  <div class="counter-area">
                    <span class="counter-item odometer" data-odometer-final={movies.reviewCount}>0</span>
                  </div>
                </div>
                <p>audience Score</p>
              </div>
              <div class="item">
                <div class="item-header">
                  <h5 class="title">4.5</h5>
                  <div class="rated">
                    <i class="fas fa-heart"></i>
                    <i class="fas fa-heart"></i>
                    <i class="fas fa-heart"></i>
                    <i class="fas fa-heart"></i>
                    <i class="fas fa-heart"></i>
                  </div>
                </div>
                <p>Users Rating</p>
              </div>
              <div class="item">
                <div class="item-header">
                  <div class="rated rate-it">
                    <i class="fas fa-heart"></i>
                    <i class="fas fa-heart"></i>
                    <i class="fas fa-heart"></i>
                    <i class="fas fa-heart"></i>
                    <i class="fas fa-heart"></i>
                  </div>
                  <h5 class="title">0.0</h5>
                </div>
                <p><a href="#0">Rate It</a></p>
              </div>
            </div>
              <a class="custom-button" onClick={(e) => MovieTicket(e, movies)}>book tickets</a>

          </div>
        </div>
      </section>
      <section class="movie-details-section padding-top padding-bottom">
        <div class="container">
          <div class="row justify-content-center flex-wrap-reverse mb--50">
            <div class="col-lg-3 col-sm-10 col-md-6 mb-50">
              <div class="widget-1 widget-tags">
                <ul>
                  {dimension.map(x =>
                    <li>
                      <a >{x}</a>
                    </li>
                  )}

                  {/* <li>
                    <a href="#0">imax 2D</a>
                  </li>
                  <li>
                    <a href="#0">4DX</a>
                  </li> */}
                </ul>
              </div>
              <div class="widget-1 widget-offer">
                <h3 class="title">Applicable offer</h3>
                <div class="offer-body">
                  <div class="offer-item">
                    <div class="thumb">
                      <img src="./assets/images/sidebar/offer01.png" alt="sidebar" />
                    </div>
                    <div class="content">
                      <h6>
                        <a href="#0">Amazon Pay Cashback Offer</a>
                      </h6>
                      <p>Win Cashback Upto Rs 300*</p>
                    </div>
                  </div>
                  <div class="offer-item">
                    <div class="thumb">
                      <img src="./assets/images/sidebar/offer02.png" alt="sidebar" />
                    </div>
                    <div class="content">
                      <h6>
                        <a href="#0">PayPal Offer</a>
                      </h6>
                      <p>Transact first time with Paypal and
                        get 100% cashback up to Rs. 500</p>
                    </div>
                  </div>
                  <div class="offer-item">
                    <div class="thumb">
                      <img src="./assets/images/sidebar/offer03.png" alt="sidebar" />
                    </div>
                    <div class="content">
                      <h6>
                        <a href="#0">HDFC Bank Offer</a>
                      </h6>
                      <p>Get 15% discount up to INR 100*
                        and INR 50* off on F&B T&C apply</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="widget-1 widget-banner">
                <div class="widget-1-body">
                  <a href="#0">
                    <img src="./assets/images/sidebar/banner/banner01.jpg" alt="banner" />
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-9 mb-50">
              <div class="movie-details">
                <h3 class="title">photos</h3>
                <div class="details-photos owl-carousel">
                  <div class="thumb">
                    <a href="./assets/images/movie/movie-details01.jpg" class="img-pop">
                      <img src="./assets/images/movie/movie-details01.jpg" alt="movie" />
                    </a>
                  </div>
                  <div class="thumb">
                    <a href="./assets/images/movie/movie-details02.jpg" class="img-pop">
                      <img src="./assets/images/movie/movie-details02.jpg" alt="movie" />
                    </a>
                  </div>
                  <div class="thumb">
                    <a href="./assets/images/movie/movie-details03.jpg" class="img-pop">
                      <img src="./assets/images/movie/movie-details03.jpg" alt="movie" />
                    </a>
                  </div>
                  <div class="thumb">
                    <a href="./assets/images/movie/movie-details01.jpg" class="img-pop">
                      <img src="./assets/images/movie/movie-details01.jpg" alt="movie" />
                    </a>
                  </div>
                  <div class="thumb">
                    <a href="./assets/images/movie/movie-details02.jpg" class="img-pop">
                      <img src="./assets/images/movie/movie-details02.jpg" alt="movie" />
                    </a>
                  </div>
                  <div class="thumb">
                    <a href="./assets/images/movie/movie-details03.jpg" class="img-pop">
                      <img src="./assets/images/movie/movie-details03.jpg" alt="movie" />
                    </a>
                  </div>
                </div>
                <div class="tab summery-review">
                  <ul class="tab-menu">
                    <li class="active">
                      summery
                    </li>
                    <li>
                      user review <span>{movies.reviewCount}</span>
                    </li>
                  </ul>
                  <div class="tab-area">
                    <div class="tab-item active">
                      <div class="item">
                        <h5 class="sub-title">Synopsis</h5>
                        <p>{movies.about}</p>
                      </div>
                      {cast.length ?

                        <div class="item">
                          <div class="header">
                            <h5 class="sub-title">cast</h5>
                            <div class="navigation">
                              <div class="cast-prev"><i class="flaticon-double-right-arrows-angles"></i></div>
                              <div class="cast-next"><i class="flaticon-double-right-arrows-angles"></i></div>
                            </div>
                          </div>
                          {/* <div class="casting-slider owl-carousel">

                            {cast.map(x =>
                              <div class="cast-item">
                                <div class="cast-thumb">
                                  <a href="#0">
                                    <img src="./assets/images/cast/cast01.jpg" alt="cast" />
                                  </a>
                                </div>
                                <div class="cast-content">
                                  <h6 class="cast-title"><a href="#0">Bill Hader</a></h6>
                                  <span class="cate">actor</span>
                                  <p>As Richie Tozier</p>
                                </div>
                              </div>
                            )}
                          </div> */}
                          <div>
                            <OwlCarousel items={10}
                              className="owl-carousel casting-slider "
                              loop
                              nav
                              margin={8}
                              center
                              autoplay={true}
                              dots={false}
                              touchDrag={true}
                              lazyLoad={true}
                              responsive={owlstate.responsive}
                              animateOut={'fadeOut'}
                              animateIn={'flipInX'}
                            >
                              {cast.map(x =>
                                <div class="cast-item">
                                  <div class="cast-thumb">
                                    <a href="#0">
                                      <img src={x.actorImage} alt="cast" />
                                    </a>
                                  </div>
                                  <div class="cast-content">
                                    <h6 class="cast-title"><a href="#0">{x.name}</a></h6>
                                    <span class="cate">{x.designation}</span>
                                    {/* <p>As Richie Tozier</p> */}
                                  </div>
                                </div>
                              )}
                            </OwlCarousel>
                          </div>

                          {/* <div class="cast-item">
                            <div class="cast-thumb">
                              <a href="#0">
                                <img src="./assets/images/cast/cast02.jpg" alt="cast" />
                              </a>
                            </div>
                            <div class="cast-content">
                              <h6 class="cast-title"><a href="#0">nora hardy</a></h6>
                              <span class="cate">actor</span>
                              <p>As raven</p>
                            </div>
                          </div>
                          <div class="cast-item">
                            <div class="cast-thumb">
                              <a href="#0">
                                <img src="./assets/images/cast/cast03.jpg" alt="cast" />
                              </a>
                            </div>
                            <div class="cast-content">
                              <h6 class="cast-title"><a href="#0">alvin peters</a></h6>
                              <span class="cate">actor</span>
                              <p>As magneto</p>
                            </div>
                          </div>
                          <div class="cast-item">
                            <div class="cast-thumb">
                              <a href="#0">
                                <img src="./assets/images/cast/cast04.jpg" alt="cast" />
                              </a>
                            </div>
                            <div class="cast-content">
                              <h6 class="cast-title"><a href="#0">josh potter</a></h6>
                              <span class="cate">actor</span>
                              <p>As quicksilver</p>
                            </div>
                          </div> */}
                        </div>
                        : ""}
                      {crew.length ?

                        <div class="item">
                          <div class="header">
                            <h5 class="sub-title">crew</h5>
                            <div class="navigation">
                              <div class="cast-prev-2"><i class="flaticon-double-right-arrows-angles"></i></div>
                              <div class="cast-next-2"><i class="flaticon-double-right-arrows-angles"></i></div>
                            </div>
                          </div>
                          {/* <div class="casting-slider-two owl-carousel">
                          <div class="cast-item">
                            <div class="cast-thumb">
                              <a href="#0">
                                <img src="./assets/images/cast/cast05.jpg" alt="cast" />
                              </a>
                            </div>
                            <div class="cast-content">
                              <h6 class="cast-title"><a href="#0">pete warren</a></h6>
                              <span class="cate">actor</span>
                            </div>
                          </div>
                         
                        </div> */}
                          <div>
                            <OwlCarousel items={10}
                              className="owl-carousel casting-slider-two "
                              loop
                              nav
                              margin={8}
                              center
                              autoplay={true}
                              dots={false}
                              touchDrag={true}
                              lazyLoad={true}
                              responsive={owlstate.responsive}
                              animateOut={'fadeOut'}
                              animateIn={'flipInX'}
                            >
                              {crew.map(x =>
                                <div class="cast-item">
                                  <div class="cast-thumb">
                                    <a href="#0">
                                      <img src={x.actorImage} alt="cast" />
                                    </a>
                                  </div>
                                  <div class="cast-content">
                                    <h6 class="cast-title"><a href="#0">{x.name}</a></h6>
                                    <span class="cate">{x.designation}</span>
                                    {/* <p>As Richie Tozier</p> */}
                                  </div>
                                </div>
                              )}
                            </OwlCarousel>
                          </div>
                        </div>
                        : ""}

                    </div>
                    <div class="tab-item">
                      {review.map(x =>
                        <div class="movie-review-item">
                          <div class="author">
                            <div class="thumb">
                              <a href="#0">
                                <img src="./assets/images/cast/cast02.jpg" alt="cast" />
                              </a>
                            </div>
                            <div class="movie-review-info">
                              <span class="reply-date">{x.createdAt}</span>
                              <h6 class="subtitle"><a href="#0">{x.userName}</a></h6>
                              <span><i class="fas fa-check"></i> verified review</span>
                            </div>
                          </div>
                          <div class="movie-review-content">
                            <div class="review">
                              <i class="flaticon-favorite-heart-button"></i>
                              <i class="flaticon-favorite-heart-button"></i>
                              <i class="flaticon-favorite-heart-button"></i>
                              <i class="flaticon-favorite-heart-button"></i>
                              <i class="flaticon-favorite-heart-button"></i>
                            </div>
                            <h6 class="cont-title">{x.title}</h6>
                            <p>{x.content} </p>
                            <div class="review-meta">
                              <a href="#0">
                                <i class="flaticon-hand"></i><span>8</span>
                              </a>
                              <a href="#0" class="dislike">
                                <i class="flaticon-dont-like-symbol"></i><span>0</span>
                              </a>
                              <a href="#0">
                                Report Abuse
                              </a>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* <div class="movie-review-item">
                        <div class="author">
                          <div class="thumb">
                            <a href="#0">
                              <img src="./assets/images/cast/cast04.jpg" alt="cast" />
                            </a>
                          </div>
                          <div class="movie-review-info">
                            <span class="reply-date">13 Days Ago</span>
                            <h6 class="subtitle"><a href="#0">rudra rai</a></h6>
                            <span><i class="fas fa-check"></i> verified review</span>
                          </div>
                        </div>
                        <div class="movie-review-content">
                          <div class="review">
                            <i class="flaticon-favorite-heart-button"></i>
                            <i class="flaticon-favorite-heart-button"></i>
                            <i class="flaticon-favorite-heart-button"></i>
                            <i class="flaticon-favorite-heart-button"></i>
                            <i class="flaticon-favorite-heart-button"></i>
                          </div>
                          <h6 class="cont-title">Awesome Movie</h6>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer volutpat enim non ante egestas vehicula. Suspendisse potenti. Fusce malesuada fringilla lectus venenatis porttitor. </p>
                          <div class="review-meta">
                            <a href="#0">
                              <i class="flaticon-hand"></i><span>8</span>
                            </a>
                            <a href="#0" class="dislike">
                              <i class="flaticon-dont-like-symbol"></i><span>0</span>
                            </a>
                            <a href="#0">
                              Report Abuse
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="movie-review-item">
                        <div class="author">
                          <div class="thumb">
                            <a href="#0">
                              <img src="./assets/images/cast/cast01.jpg" alt="cast" />
                            </a>
                          </div>
                          <div class="movie-review-info">
                            <span class="reply-date">13 Days Ago</span>
                            <h6 class="subtitle"><a href="#0">rafuj</a></h6>
                            <span><i class="fas fa-check"></i> verified review</span>
                          </div>
                        </div>
                        <div class="movie-review-content">
                          <div class="review">
                            <i class="flaticon-favorite-heart-button"></i>
                            <i class="flaticon-favorite-heart-button"></i>
                            <i class="flaticon-favorite-heart-button"></i>
                            <i class="flaticon-favorite-heart-button"></i>
                            <i class="flaticon-favorite-heart-button"></i>
                          </div>
                          <h6 class="cont-title">Awesome Movie</h6>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer volutpat enim non ante egestas vehicula. Suspendisse potenti. Fusce malesuada fringilla lectus venenatis porttitor. </p>
                          <div class="review-meta">
                            <a href="#0">
                              <i class="flaticon-hand"></i><span>8</span>
                            </a>
                            <a href="#0" class="dislike">
                              <i class="flaticon-dont-like-symbol"></i><span>0</span>
                            </a>
                            <a href="#0">
                              Report Abuse
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="movie-review-item">
                        <div class="author">
                          <div class="thumb">
                            <a href="#0">
                              <img src="./assets/images/cast/cast03.jpg" alt="cast" />
                            </a>
                          </div>
                          <div class="movie-review-info">
                            <span class="reply-date">13 Days Ago</span>
                            <h6 class="subtitle"><a href="#0">bela bose</a></h6>
                            <span><i class="fas fa-check"></i> verified review</span>
                          </div>
                        </div>
                        <div class="movie-review-content">
                          <div class="review">
                            <i class="flaticon-favorite-heart-button"></i>
                            <i class="flaticon-favorite-heart-button"></i>
                            <i class="flaticon-favorite-heart-button"></i>
                            <i class="flaticon-favorite-heart-button"></i>
                            <i class="flaticon-favorite-heart-button"></i>
                          </div>
                          <h6 class="cont-title">Awesome Movie</h6>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer volutpat enim non ante egestas vehicula. Suspendisse potenti. Fusce malesuada fringilla lectus venenatis porttitor. </p>
                          <div class="review-meta">
                            <a href="#0">
                              <i class="flaticon-hand"></i><span>8</span>
                            </a>
                            <a href="#0" class="dislike">
                              <i class="flaticon-dont-like-symbol"></i><span>0</span>
                            </a>
                            <a href="#0">
                              Report Abuse
                            </a>
                          </div>
                        </div>
                      </div> */}
                      <div class="load-more text-center">
                        <a href="#0" class="custom-button transparent">load more</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default MovieDetails