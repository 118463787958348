import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import axios from 'axios';
import common_url from '../../http_common';


function MovieTicketPlan() {
  const { state } = useLocation();
  console.log(state, 'ticketstate')
  const[date,setDate]= useState('')
  // var movieName= state.movieName;

  const GetTheatreList = async () => {
    axios.get(common_url+'booking/searchAPIForTheatreWithShowDetails/'  + 'madurai' + '/' + state.movieName+ '/'+date ).then(res => {
      console.log(res.data.message, 'lsit')
      // setExchangeList(res.data.data.result);
    });
  }
  return (
    <div>
      <Header />
      <section class="window-warning inActive">
        <div class="lay"></div>
        <div class="warning-item">
          <h6 class="subtitle">Welcome! </h6>
          <h4 class="title">Select Your Seats</h4>
          <div class="thumb">
            <img src="./assets/images/movie/seat-plan.png" alt="movie" />
          </div>
          <a href="movie-seat-plan.html" class="custom-button seatPlanButton">Seat Plans<i class="fas fa-angle-right"></i></a>
        </div>
      </section>
      <section class="details-banner hero-area bg_img" data-background="./assets/images/banner/banner03.jpg">
        <div class="container">
          <div class="details-banner-wrapper">
            <div class="details-banner-content">
              <h3 class="title">Venus</h3>
              <div class="tags">
                <a href="#0">English</a>
                <a href="#0">Hindi</a>
                <a href="#0">Telegu</a>
                <a href="#0">Tamil</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="book-section bg-one">
        <div class="container">
          <form class="ticket-search-form two">
            {/* <div class="form-group">
              <div class="thumb">
                <img src="./assets/images/ticket/city.png" alt="ticket" />
              </div>
              <span class="type">city</span>
              <select class="select-bar">
                <option value="london">London</option>
                <option value="dhaka">dhaka</option>
                <option value="rosario">rosario</option>
                <option value="madrid">madrid</option>
                <option value="koltaka">kolkata</option>
                <option value="rome">rome</option>
                <option value="khoksa">khoksa</option>
              </select>

            </div> */}
            <div class="form-group">
              <div class="thumb">
                <img src="./assets/images/ticket/date.png" alt="ticket" />
              </div>
              <span class="type">date</span>
              <select class="form-select" style={{
                background: ' #2d4186',
                color: 'white',
                width: '200px',
                height: '36px',
                marginLeft: '5px'
              }} onChange={(e) => setDate(e.target.value)}>
                <option selected>select Date</option>
                <option value="2022-07-05">09/07/2022</option>
                <option value="2022-07-06">09/07/2022</option>
                <option value="2022-07-07">09/07/2022</option>
                <option value="2022-07-08">09/07/2022</option>
                <option value="2022-07-05">09/07/2022</option>
                <option value="2022-07-09">09/07/2022</option>
                <option value="2022-07-09">09/07/2022</option>
                <option value="2022-07-03">09/07/2022</option>
                <option value="2022-07-07">09/07/2022</option>
                <option value="2022-07-08">09/07/2022</option>
              </select>
            </div>
            {/* <div class="form-group">
              <div class="thumb">
                <img src="./assets/images/ticket/cinema.png" alt="ticket" />
              </div>
              <span class="type">cinema</span>
              <select class="select-bar">
                <option value="Awaken">Awaken</option>
                <option value="Venus">Venus</option>
                <option value="wanted">wanted</option>
                <option value="joker">joker</option>
                <option value="fid">fid</option>
                <option value="kidio">kidio</option>
                <option value="mottus">mottus</option>
              </select>
            </div> */}
            <div class="form-group">
              <div class="thumb">
                {/* <img src="./assets/images/ticket/exp.png" alt="ticket" />
                 */}
                 <a  onClick={(e) => GetTheatreList()}>get</a>
              </div>
             
            </div>
          </form>
        </div>
      </section>
      <div class="ticket-plan-section padding-bottom padding-top">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-9 mb-5 mb-lg-0">
              <ul class="seat-plan-wrapper bg-five">
                <li>
                  <div class="movie-name">
                    <div class="icons">
                      <i class="far fa-heart"></i>
                      <i class="fas fa-heart"></i>
                    </div>
                    <a href="#0" class="name">Genesis Cinema</a>
                    <div class="location-icon">
                      <i class="fas fa-map-marker-alt"></i>
                    </div>
                  </div>
                  <div class="movie-schedule">
                    <div class="item">
                      09:40
                    </div>
                    <div class="item">
                      13:45
                    </div>
                    <div class="item">
                      15:45
                    </div>
                    <div class="item">
                      19:50
                    </div>
                  </div>
                </li>
                <li>
                  <div class="movie-name">
                    <div class="icons">
                      <i class="far fa-heart"></i>
                      <i class="fas fa-heart"></i>
                    </div>
                    <a href="#0" class="name">the beach</a>
                    <div class="location-icon">
                      <i class="fas fa-map-marker-alt"></i>
                    </div>
                  </div>
                  <div class="movie-schedule">
                    <div class="item">
                      09:40
                    </div>
                    <div class="item">
                      13:45
                    </div>
                    <div class="item">
                      15:45
                    </div>
                    <div class="item">
                      19:50
                    </div>
                  </div>
                </li>
                <li class="active">
                  <div class="movie-name">
                    <div class="icons">
                      <i class="far fa-heart"></i>
                      <i class="fas fa-heart"></i>
                    </div>
                    <a href="#0" class="name">city work</a>
                    <div class="location-icon">
                      <i class="fas fa-map-marker-alt"></i>
                    </div>
                  </div>
                  <div class="movie-schedule">
                    <div class="item">
                      09:40
                    </div>
                    <div class="item active">
                      13:45
                    </div>
                    <div class="item">
                      15:45
                    </div>
                    <div class="item">
                      19:50
                    </div>
                  </div>
                </li>
                <li>
                  <div class="movie-name">
                    <div class="icons">
                      <i class="far fa-heart"></i>
                      <i class="fas fa-heart"></i>
                    </div>
                    <a href="#0" class="name">box park</a>
                    <div class="location-icon">
                      <i class="fas fa-map-marker-alt"></i>
                    </div>
                  </div>
                  <div class="movie-schedule">
                    <div class="item">
                      09:40
                    </div>
                    <div class="item">
                      13:45
                    </div>
                    <div class="item">
                      15:45
                    </div>
                    <div class="item">
                      19:50
                    </div>
                  </div>
                </li>
                <li>
                  <div class="movie-name">
                    <div class="icons">
                      <i class="far fa-heart"></i>
                      <i class="fas fa-heart"></i>
                    </div>
                    <a href="#0" class="name">la mer</a>
                    <div class="location-icon">
                      <i class="fas fa-map-marker-alt"></i>
                    </div>
                  </div>
                  <div class="movie-schedule">
                    <div class="item">
                      09:40
                    </div>
                    <div class="item">
                      13:45
                    </div>
                    <div class="item">
                      15:45
                    </div>
                    <div class="item">
                      19:50
                    </div>
                  </div>
                </li>
                <li>
                  <div class="movie-name">
                    <div class="icons">
                      <i class="far fa-heart"></i>
                      <i class="fas fa-heart"></i>
                    </div>
                    <a href="#0" class="name">wanted</a>
                    <div class="location-icon">
                      <i class="fas fa-map-marker-alt"></i>
                    </div>
                  </div>
                  <div class="movie-schedule">
                    <div class="item">
                      09:40
                    </div>
                    <div class="item">
                      13:45
                    </div>
                    <div class="item">
                      15:45
                    </div>
                    <div class="item">
                      19:50
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-10">
              <div class="widget-1 widget-banner">
                <div class="widget-1-body">
                  <a href="#0">
                    <img src="./assets/images/sidebar/banner/banner03.jpg" alt="banner" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Helmet>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"></script>

        <script src="../../assets/js/jquery-3.3.1.min.js"></script>
        <script src="../../assets/js/modernizr-3.6.0.min.js"></script>
        <script src="../../assets/js/plugins.js"></script>
        <script src="../../assets/js/bootstrap.min.js"></script>
        <script src="../../assets/js/heandline.js"></script>
        <script src="../../assets/js/isotope.pkgd.min.js"></script>
        <script src="../../assets/js/magnific-popup.min.js"></script>
        <script src="../../assets/js/owl.carousel.min.js"></script>
        <script src="../../assets/js/wow.min.js"></script>
        <script src="../../assets/js/countdown.min.js"></script>
        <script src="../../assets/js/odometer.min.js"></script>
        <script src="../../assets/js/viewport.jquery.js"></script>
        <script src="../../assets/js/nice-select.js"></script>
        <script src="../../assets/js/main.js"></script>
      </Helmet>

    </div>
  )
}

export default MovieTicketPlan