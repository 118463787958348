import React, { useState, useEffect } from 'react';
import Footer from '../Footer/Footer';
import axios from 'axios';
import Header from './../Header/Header';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import common_url from '../../http_common';

function Movie() {
  const [movies, setMovies] = useState([]);
  const [city, setCity] = useState('');
  const [cityFilter, setCityFilter] = useState([]);


  const nav = useNavigate()

  useEffect(() => {
    getMovies();
  }, []);


  const getMovies = () => {
    axios.get(common_url+'movie/getMovieDetails').then(res => {
      // console.log(res.data.message, 'resmovies')
      setMovies(res.data.message);
    });
  }
  function movieDetails(e, x) {
    nav('/moviedetails', { state: x })
  }
  async function GetLocation() {
    await axios({
      'method': 'GET',
      'url': common_url+'booking/searchAPI/' + city,
    })
      .then((res) => {
        console.log(res.data.message, 'getMovie')
        setCityFilter(res.data.message)
      }).catch((err) => {
        console.log("ERR =>", err);
      })
  }

  return (
    <div>
      <Header />
      <section class="banner-section">
        <div class="banner-bg bg_img bg-fixed" data-background="./assets/images/banner/banner02.jpg"></div>
        <div class="container">
          <div class="banner-content">
            <h1 class="title bold">get <span class="color-theme">movie</span> tickets</h1>
            <p>Buy movie tickets in advance, find movie times watch trailers, read movie reviews and much more</p>
          </div>
        </div>
      </section>
      <section class="search-ticket-section padding-top pt-lg-0">
        <div class="container">
          <div class="search-tab bg_img" data-background="./assets/images/ticket/ticket-bg01.jpg">
            <div class="row align-items-center mb--20">
              <div class="col-lg-6 mb-20">
                <div class="search-ticket-header">
                  <h6 class="category">welcome to Boleto </h6>
                  <h3 class="title">what are you looking for</h3>
                </div>
              </div>
              <div class="col-lg-6 mb-20">
                <ul class=" ticket-tab-menu">
                  <li class="active">
                    <Link to='/movie'>
                      <div class="tab-thumb">
                        <img src="./assets/images/ticket/ticket-tab01.png" alt="ticket" />
                      </div>
                      <span>movie</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/events'>
                      <div class="tab-thumb">
                        <img src="./assets/images/ticket/ticket-tab02.png" alt="ticket" />
                      </div>
                      <span>events</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/sports'>
                      <div class="tab-thumb">
                        <img src="./assets/images/ticket/ticket-tab03.png" alt="ticket" />
                      </div>
                      <span>sports</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="tab-area">
              <div class="tab-item active">
                <form class="ticket-search-form">
                  <div class="row form-group large">
                    <div class="col-lg-6">
                      <input type="text" placeholder="Search for City" onChange={(e) => setCity(e.target.value)} />
                      <button type="submit"><i class="fas fa-search"></i></button>
                    </div>
                    <div class="col-lg-6">
                      <span>
                        <a class=" search-btn" onClick={(e) => {
                          GetLocation(e)
                        }}> search</a>
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div >
      </section >
      <section class="movie-section padding-top padding-bottom">
        <div class="container">
          <div class="row flex-wrap-reverse justify-content-center">
            <div class="col-sm-10 col-md-8 col-lg-3">
              <div class="widget-1 widget-banner">
                <div class="widget-1-body">
                  <a href="#0">
                    <img src="./assets/images/sidebar/banner/banner01.jpg" alt="banner" />
                  </a>
                </div>
              </div>
              <div class="widget-1 widget-check">
                <div class="widget-header">
                  <h5 class="m-title">Filter By</h5> <a href="#0" class="clear-check">Clear All</a>
                </div>
                <div class="widget-1-body">
                  <h6 class="subtitle">Language</h6>
                  <div class="check-area">
                    <div class="form-group">
                      <input type="checkbox" name="lang" id="lang1" /><label for="lang1">Tamil</label>
                    </div>
                    <div class="form-group">
                      <input type="checkbox" name="lang" id="lang2" /><label for="lang2">Telegu</label>
                    </div>
                    <div class="form-group">
                      <input type="checkbox" name="lang" id="lang3" /><label for="lang3">Hindi</label>
                    </div>
                    <div class="form-group">
                      <input type="checkbox" name="lang" id="lang4" /><label for="lang4">English</label>
                    </div>
                    <div class="form-group">
                      <input type="checkbox" name="lang" id="lang5" /><label for="lang5">Multiple Language</label>
                    </div>
                    <div class="form-group">
                      <input type="checkbox" name="lang" id="lang6" /><label for="lang6">Gujrati</label>
                    </div>
                    <div class="form-group">
                      <input type="checkbox" name="lang" id="lang7" /><label for="lang7">Bangla</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="widget-1 widget-check">
                <div class="widget-1-body">
                  <h6 class="subtitle">experience</h6>
                  <div class="check-area">
                    <div class="form-group">
                      <input type="checkbox" name="mode" id="mode1" /><label for="mode1">2d</label>
                    </div>
                    <div class="form-group">
                      <input type="checkbox" name="mode" id="mode2" /><label for="mode2">3d</label>
                    </div>
                  </div>
                  <div class="add-check-area">
                    <a href="#0">view more <i class="plus"></i></a>
                  </div>
                </div>
              </div>
              <div class="widget-1 widget-check">
                <div class="widget-1-body">
                  <h6 class="subtitle">genre</h6>
                  <div class="check-area">
                    <div class="form-group">
                      <input type="checkbox" name="genre" id="genre1" /><label for="genre1">thriller</label>
                    </div>
                    <div class="form-group">
                      <input type="checkbox" name="genre" id="genre2" /><label for="genre2">horror</label>
                    </div>
                    <div class="form-group">
                      <input type="checkbox" name="genre" id="genre3" /><label for="genre3">drama</label>
                    </div>
                    <div class="form-group">
                      <input type="checkbox" name="genre" id="genre4" /><label for="genre4">romance</label>
                    </div>
                    <div class="form-group">
                      <input type="checkbox" name="genre" id="genre5" /><label for="genre5">action</label>
                    </div>
                    <div class="form-group">
                      <input type="checkbox" name="genre" id="genre6" /><label for="genre6">comedy</label>
                    </div>
                    <div class="form-group">
                      <input type="checkbox" name="genre" id="genre7" /><label for="genre7">romantic</label>
                    </div>
                    <div class="form-group">
                      <input type="checkbox" name="genre" id="genre8" /><label for="genre8">animation</label>
                    </div>
                    <div class="form-group">
                      <input type="checkbox" name="genre" id="genre9" /><label for="genre9">sci-fi</label>
                    </div>
                    <div class="form-group">
                      <input type="checkbox" name="genre" id="genre10" /><label for="genre10">adventure</label>
                    </div>
                  </div>
                  <div class="add-check-area">
                    <a href="#0">view more <i class="plus"></i></a>
                  </div>
                </div>
              </div>
              <div class="widget-1 widget-banner">
                <div class="widget-1-body">
                  <a href="#0">
                    <img src="./assets/images/sidebar/banner/banner02.jpg" alt="banner" />
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-9 mb-50 mb-lg-0">
              <div class="filter-tab tab">
                <div class="filter-area">
                  <div class="filter-main">
                    <div class="left">
                      <div class="item">
                        <span class="show">Show :</span>
                        <select class="select-bar">
                          <option value="12">12</option>
                          <option value="15">15</option>
                          <option value="18">18</option>
                          <option value="21">21</option>
                          <option value="24">24</option>
                          <option value="27">27</option>
                          <option value="30">30</option>
                        </select>
                      </div>
                      <div class="item">
                        <span class="show">Sort By :</span>
                        <select class="select-bar">
                          <option value="showing">now showing</option>
                          <option value="exclusive">exclusive</option>
                          <option value="trending">trending</option>
                          <option value="most-view">most view</option>
                        </select>
                      </div>
                    </div>
                    <ul class="grid-button tab-menu">
                      <li class="active">
                        <i class="fas fa-th"></i>
                      </li>
                      <li>
                        <i class="fas fa-bars"></i>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="tab-area">
                  <div class="tab-item active">
                    <div class="row mb-10 justify-content-center">
                      {cityFilter.length > 0 ?

                        cityFilter.map(x =>
                          <div class="col-sm-6 col-lg-4">
                            <div class="movie-grid">
                              <div class="movie-thumb c-thumb">
                                <a>
                                  <img src={x.movieImage} alt="movie" onClick={(e) => movieDetails(e, x)} />
                                </a>
                              </div>
                              <div class="movie-content bg-one">
                                <h5 class="title m-0">
                                  <a onClick={(e) => movieDetails(e, x)}>{x.movieName}</a>
                                </h5>
                                <ul class="movie-rating-percent">
                                  <li>
                                    <div class="thumb">
                                      <img src="./assets/images/movie/tomato.png" alt="movie" />
                                    </div>
                                    <span class="content">{x.rating}%</span>
                                  </li>
                                  <li>
                                    <div class="thumb">
                                      <img src="./assets/images/movie/cake.png" alt="movie" />
                                    </div>
                                    <span class="content">{x.reviewCount}%</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>)
                        : <>
                          {movies.map(x =>
                            <div class="col-sm-6 col-lg-4">
                              <div class="movie-grid">
                                <div class="movie-thumb c-thumb">
                                  <a>
                                    <img src={x.movieImage} alt="movie" onClick={(e) => movieDetails(e, x)} />
                                  </a>
                                </div>
                                <div class="movie-content bg-one">
                                  <h5 class="title m-0">
                                    <a onClick={(e) => movieDetails(e, x)}>{x.movieName}</a>
                                  </h5>
                                  <ul class="movie-rating-percent">
                                    <li>
                                      <div class="thumb">
                                        <img src="./assets/images/movie/tomato.png" alt="movie" />
                                      </div>
                                      <span class="content">{x.rating}%</span>
                                    </li>
                                    <li>
                                      <div class="thumb">
                                        <img src="./assets/images/movie/cake.png" alt="movie" />
                                      </div>
                                      <span class="content">{x.reviewCount}%</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )}

                        </>
                      }

                    </div>
                  </div>
                  <div class="tab-item">
                    <div class="movie-area mb-10">
                      <div class="movie-list">
                        <div class="movie-thumb c-thumb">
                          <a href="movie-details.html" class="w-100 bg_img h-100" data-background="./assets/images/movie/movie01.jpg">
                            <img class="d-sm-none" src="./assets/images/movie/movie01.jpg" alt="movie" />
                          </a>
                        </div>
                        <div class="movie-content bg-one">
                          <h5 class="title">
                            <a href="movie-details.html">alone</a>
                          </h5>
                          <p class="duration">2hrs 50 min</p>
                          <div class="movie-tags">
                            <a href="#0">action</a>
                            <a href="#0">adventure</a>
                            <a href="#0">fantasy</a>
                          </div>
                          <div class="release">
                            <span>Release Date : </span> <a href="#0"> November 8 , 2020</a>
                          </div>
                          <ul class="movie-rating-percent">
                            <li>
                              <div class="thumb">
                                <img src="./assets/images/movie/tomato.png" alt="movie" />
                              </div>
                              <span class="content">88%</span>
                            </li>
                            <li>
                              <div class="thumb">
                                <img src="./assets/images/movie/cake.png" alt="movie" />
                              </div>
                              <span class="content">88%</span>
                            </li>
                          </ul>
                          <div class="book-area">
                            <div class="book-ticket">
                              <div class="react-item">
                                <a href="#0">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/heart.png" alt="icons" />
                                  </div>
                                </a>
                              </div>
                              <div class="react-item mr-auto">
                                <a href="#0">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/book.png" alt="icons" />
                                  </div>
                                  <span>book ticket</span>
                                </a>
                              </div>
                              <div class="react-item">
                                <a href="#0" class="popup-video">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/play-button.png" alt="icons" />
                                  </div>
                                  <span>watch trailer</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="movie-list">
                        <div class="movie-thumb c-thumb">
                          <a href="movie-details.html" class="w-100 bg_img h-100" data-background="./assets/images/movie/movie02.jpg">
                            <img class="d-sm-none" src="./assets/images/movie/movie02.jpg" alt="movie" />
                          </a>
                        </div>
                        <div class="movie-content bg-one">
                          <h5 class="title">
                            <a href="movie-details.html">mars</a>
                          </h5>
                          <p class="duration">2hrs 50 min</p>
                          <div class="movie-tags">
                            <a href="#0">action</a>
                            <a href="#0">adventure</a>
                            <a href="#0">fantasy</a>
                          </div>
                          <div class="release">
                            <span>Release Date : </span> <a href="#0"> November 8 , 2020</a>
                          </div>
                          <ul class="movie-rating-percent">
                            <li>
                              <div class="thumb">
                                <img src="./assets/images/movie/tomato.png" alt="movie" />
                              </div>
                              <span class="content">88%</span>
                            </li>
                            <li>
                              <div class="thumb">
                                <img src="./assets/images/movie/cake.png" alt="movie" />
                              </div>
                              <span class="content">88%</span>
                            </li>
                          </ul>
                          <div class="book-area">
                            <div class="book-ticket">
                              <div class="react-item">
                                <a href="#0">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/heart.png" alt="icons" />
                                  </div>
                                </a>
                              </div>
                              <div class="react-item mr-auto">
                                <a href="#0">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/book.png" alt="icons" />
                                  </div>
                                  <span>book ticket</span>
                                </a>
                              </div>
                              <div class="react-item">
                                <a href="#0" class="popup-video">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/play-button.png" alt="icons" />
                                  </div>
                                  <span>watch trailer</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="movie-list">
                        <div class="movie-thumb c-thumb">
                          <a href="movie-details.html" class="w-100 bg_img h-100" data-background="./assets/images/movie/movie03.jpg">
                            <img class="d-sm-none" src="./assets/images/movie/movie03.jpg" alt="movie" />
                          </a>
                        </div>
                        <div class="movie-content bg-one">
                          <h5 class="title">
                            <a href="movie-details.html">venus</a>
                          </h5>
                          <p class="duration">2hrs 50 min</p>
                          <div class="movie-tags">
                            <a href="#0">action</a>
                            <a href="#0">adventure</a>
                            <a href="#0">fantasy</a>
                          </div>
                          <div class="release">
                            <span>Release Date : </span> <a href="#0"> November 8 , 2020</a>
                          </div>
                          <ul class="movie-rating-percent">
                            <li>
                              <div class="thumb">
                                <img src="./assets/images/movie/tomato.png" alt="movie" />
                              </div>
                              <span class="content">88%</span>
                            </li>
                            <li>
                              <div class="thumb">
                                <img src="./assets/images/movie/cake.png" alt="movie" />
                              </div>
                              <span class="content">88%</span>
                            </li>
                          </ul>
                          <div class="book-area">
                            <div class="book-ticket">
                              <div class="react-item">
                                <a href="#0">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/heart.png" alt="icons" />
                                  </div>
                                </a>
                              </div>
                              <div class="react-item mr-auto">
                                <a href="#0">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/book.png" alt="icons" />
                                  </div>
                                  <span>book ticket</span>
                                </a>
                              </div>
                              <div class="react-item">
                                <a href="#0" class="popup-video">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/play-button.png" alt="icons" />
                                  </div>
                                  <span>watch trailer</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="movie-list">
                        <div class="movie-thumb c-thumb">
                          <a href="movie-details.html" class="w-100 bg_img h-100" data-background="./assets/images/movie/movie04.jpg">
                            <img class="d-sm-none" src="./assets/images/movie/movie04.jpg" alt="movie" />
                          </a>
                        </div>
                        <div class="movie-content bg-one">
                          <h5 class="title">
                            <a href="movie-details.html">on watch</a>
                          </h5>
                          <p class="duration">2hrs 50 min</p>
                          <div class="movie-tags">
                            <a href="#0">action</a>
                            <a href="#0">adventure</a>
                            <a href="#0">fantasy</a>
                          </div>
                          <div class="release">
                            <span>Release Date : </span> <a href="#0"> November 8 , 2020</a>
                          </div>
                          <ul class="movie-rating-percent">
                            <li>
                              <div class="thumb">
                                <img src="./assets/images/movie/tomato.png" alt="movie" />
                              </div>
                              <span class="content">88%</span>
                            </li>
                            <li>
                              <div class="thumb">
                                <img src="./assets/images/movie/cake.png" alt="movie" />
                              </div>
                              <span class="content">88%</span>
                            </li>
                          </ul>
                          <div class="book-area">
                            <div class="book-ticket">
                              <div class="react-item">
                                <a href="#0">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/heart.png" alt="icons" />
                                  </div>
                                </a>
                              </div>
                              <div class="react-item mr-auto">
                                <a href="#0">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/book.png" alt="icons" />
                                  </div>
                                  <span>book ticket</span>
                                </a>
                              </div>
                              <div class="react-item">
                                <a href="#0" class="popup-video">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/play-button.png" alt="icons" />
                                  </div>
                                  <span>watch trailer</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="movie-list">
                        <div class="movie-thumb c-thumb">
                          <a href="movie-details.html" class="w-100 bg_img h-100" data-background="./assets/images/movie/movie05.jpg">
                            <img class="d-sm-none" src="./assets/images/movie/movie05.jpg" alt="movie" />
                          </a>
                        </div>
                        <div class="movie-content bg-one">
                          <h5 class="title">
                            <a href="movie-details.html">fury</a>
                          </h5>
                          <p class="duration">2hrs 50 min</p>
                          <div class="movie-tags">
                            <a href="#0">action</a>
                            <a href="#0">adventure</a>
                            <a href="#0">fantasy</a>
                          </div>
                          <div class="release">
                            <span>Release Date : </span> <a href="#0"> November 8 , 2020</a>
                          </div>
                          <ul class="movie-rating-percent">
                            <li>
                              <div class="thumb">
                                <img src="./assets/images/movie/tomato.png" alt="movie" />
                              </div>
                              <span class="content">88%</span>
                            </li>
                            <li>
                              <div class="thumb">
                                <img src="./assets/images/movie/cake.png" alt="movie" />
                              </div>
                              <span class="content">88%</span>
                            </li>
                          </ul>
                          <div class="book-area">
                            <div class="book-ticket">
                              <div class="react-item">
                                <a href="#0">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/heart.png" alt="icons" />
                                  </div>
                                </a>
                              </div>
                              <div class="react-item mr-auto">
                                <a href="#0">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/book.png" alt="icons" />
                                  </div>
                                  <span>book ticket</span>
                                </a>
                              </div>
                              <div class="react-item">
                                <a href="#0" class="popup-video">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/play-button.png" alt="icons" />
                                  </div>
                                  <span>watch trailer</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="movie-list">
                        <div class="movie-thumb c-thumb">
                          <a href="movie-details.html" class="w-100 bg_img h-100" data-background="./assets/images/movie/movie06.jpg">
                            <img class="d-sm-none" src="./assets/images/movie/movie06.jpg" alt="movie" />
                          </a>
                        </div>
                        <div class="movie-content bg-one">
                          <h5 class="title">
                            <a href="movie-details.html">trooper</a>
                          </h5>
                          <p class="duration">2hrs 50 min</p>
                          <div class="movie-tags">
                            <a href="#0">action</a>
                            <a href="#0">adventure</a>
                            <a href="#0">fantasy</a>
                          </div>
                          <div class="release">
                            <span>Release Date : </span> <a href="#0"> November 8 , 2020</a>
                          </div>
                          <ul class="movie-rating-percent">
                            <li>
                              <div class="thumb">
                                <img src="./assets/images/movie/tomato.png" alt="movie" />
                              </div>
                              <span class="content">88%</span>
                            </li>
                            <li>
                              <div class="thumb">
                                <img src="./assets/images/movie/cake.png" alt="movie" />
                              </div>
                              <span class="content">88%</span>
                            </li>
                          </ul>
                          <div class="book-area">
                            <div class="book-ticket">
                              <div class="react-item">
                                <a href="#0">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/heart.png" alt="icons" />
                                  </div>
                                </a>
                              </div>
                              <div class="react-item mr-auto">
                                <a href="#0">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/book.png" alt="icons" />
                                  </div>
                                  <span>book ticket</span>
                                </a>
                              </div>
                              <div class="react-item">
                                <a href="#0" class="popup-video">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/play-button.png" alt="icons" />
                                  </div>
                                  <span>watch trailer</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="movie-list">
                        <div class="movie-thumb c-thumb">
                          <a href="movie-details.html" class="w-100 bg_img h-100" data-background="./assets/images/movie/movie07.jpg">
                            <img class="d-sm-none" src="./assets/images/movie/movie07.jpg" alt="movie" />
                          </a>
                        </div>
                        <div class="movie-content bg-one">
                          <h5 class="title">
                            <a href="movie-details.html">horror night</a>
                          </h5>
                          <p class="duration">2hrs 50 min</p>
                          <div class="movie-tags">
                            <a href="#0">action</a>
                            <a href="#0">adventure</a>
                            <a href="#0">fantasy</a>
                          </div>
                          <div class="release">
                            <span>Release Date : </span> <a href="#0"> November 8 , 2020</a>
                          </div>
                          <ul class="movie-rating-percent">
                            <li>
                              <div class="thumb">
                                <img src="./assets/images/movie/tomato.png" alt="movie" />
                              </div>
                              <span class="content">88%</span>
                            </li>
                            <li>
                              <div class="thumb">
                                <img src="./assets/images/movie/cake.png" alt="movie" />
                              </div>
                              <span class="content">88%</span>
                            </li>
                          </ul>
                          <div class="book-area">
                            <div class="book-ticket">
                              <div class="react-item">
                                <a href="#0">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/heart.png" alt="icons" />
                                  </div>
                                </a>
                              </div>
                              <div class="react-item mr-auto">
                                <a href="#0">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/book.png" alt="icons" />
                                  </div>
                                  <span>book ticket</span>
                                </a>
                              </div>
                              <div class="react-item">
                                <a href="#0" class="popup-video">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/play-button.png" alt="icons" />
                                  </div>
                                  <span>watch trailer</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="movie-list">
                        <div class="movie-thumb c-thumb">
                          <a href="movie-details.html" class="w-100 bg_img h-100" data-background="./assets/images/movie/movie08.jpg">
                            <img class="d-sm-none" src="./assets/images/movie/movie08.jpg" alt="movie" />
                          </a>
                        </div>
                        <div class="movie-content bg-one">
                          <h5 class="title">
                            <a href="movie-details.html">the lost name</a>
                          </h5>
                          <p class="duration">2hrs 50 min</p>
                          <div class="movie-tags">
                            <a href="#0">action</a>
                            <a href="#0">adventure</a>
                            <a href="#0">fantasy</a>
                          </div>
                          <div class="release">
                            <span>Release Date : </span> <a href="#0"> November 8 , 2020</a>
                          </div>
                          <ul class="movie-rating-percent">
                            <li>
                              <div class="thumb">
                                <img src="./assets/images/movie/tomato.png" alt="movie" />
                              </div>
                              <span class="content">88%</span>
                            </li>
                            <li>
                              <div class="thumb">
                                <img src="./assets/images/movie/cake.png" alt="movie" />
                              </div>
                              <span class="content">88%</span>
                            </li>
                          </ul>
                          <div class="book-area">
                            <div class="book-ticket">
                              <div class="react-item">
                                <a href="#0">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/heart.png" alt="icons" />
                                  </div>
                                </a>
                              </div>
                              <div class="react-item mr-auto">
                                <a href="#0">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/book.png" alt="icons" />
                                  </div>
                                  <span>book ticket</span>
                                </a>
                              </div>
                              <div class="react-item">
                                <a href="#0" class="popup-video">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/play-button.png" alt="icons" />
                                  </div>
                                  <span>watch trailer</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="movie-list">
                        <div class="movie-thumb c-thumb">
                          <a href="movie-details.html" class="w-100 bg_img h-100" data-background="./assets/images/movie/movie09.jpg">
                            <img class="d-sm-none" src="./assets/images/movie/movie09.jpg" alt="movie" />
                          </a>
                        </div>
                        <div class="movie-content bg-one">
                          <h5 class="title">
                            <a href="movie-details.html">calm stedfast</a>
                          </h5>
                          <p class="duration">2hrs 50 min</p>
                          <div class="movie-tags">
                            <a href="#0">action</a>
                            <a href="#0">adventure</a>
                            <a href="#0">fantasy</a>
                          </div>
                          <div class="release">
                            <span>Release Date : </span> <a href="#0"> November 8 , 2020</a>
                          </div>
                          <ul class="movie-rating-percent">
                            <li>
                              <div class="thumb">
                                <img src="./assets/images/movie/tomato.png" alt="movie" />
                              </div>
                              <span class="content">88%</span>
                            </li>
                            <li>
                              <div class="thumb">
                                <img src="./assets/images/movie/cake.png" alt="movie" />
                              </div>
                              <span class="content">88%</span>
                            </li>
                          </ul>
                          <div class="book-area">
                            <div class="book-ticket">
                              <div class="react-item">
                                <a href="#0">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/heart.png" alt="icons" />
                                  </div>
                                </a>
                              </div>
                              <div class="react-item mr-auto">
                                <a href="#0">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/book.png" alt="icons" />
                                  </div>
                                  <span>book ticket</span>
                                </a>
                              </div>
                              <div class="react-item">
                                <a href="#0" class="popup-video">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/play-button.png" alt="icons" />
                                  </div>
                                  <span>watch trailer</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="movie-list">
                        <div class="movie-thumb c-thumb">
                          <a href="movie-details.html" class="w-100 bg_img h-100" data-background="./assets/images/movie/movie10.jpg">
                            <img class="d-sm-none" src="./assets/images/movie/movie10.jpg" alt="movie" />
                          </a>
                        </div>
                        <div class="movie-content bg-one">
                          <h5 class="title">
                            <a href="movie-details.html">criminal on party</a>
                          </h5>
                          <p class="duration">2hrs 50 min</p>
                          <div class="movie-tags">
                            <a href="#0">action</a>
                            <a href="#0">adventure</a>
                            <a href="#0">fantasy</a>
                          </div>
                          <div class="release">
                            <span>Release Date : </span> <a href="#0"> November 8 , 2020</a>
                          </div>
                          <ul class="movie-rating-percent">
                            <li>
                              <div class="thumb">
                                <img src="./assets/images/movie/tomato.png" alt="movie" />
                              </div>
                              <span class="content">88%</span>
                            </li>
                            <li>
                              <div class="thumb">
                                <img src="./assets/images/movie/cake.png" alt="movie" />
                              </div>
                              <span class="content">88%</span>
                            </li>
                          </ul>
                          <div class="book-area">
                            <div class="book-ticket">
                              <div class="react-item">
                                <a href="#0">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/heart.png" alt="icons" />
                                  </div>
                                </a>
                              </div>
                              <div class="react-item mr-auto">
                                <a href="#0">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/book.png" alt="icons" />
                                  </div>
                                  <span>book ticket</span>
                                </a>
                              </div>
                              <div class="react-item">
                                <a href="#0" class="popup-video">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/play-button.png" alt="icons" />
                                  </div>
                                  <span>watch trailer</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="movie-list">
                        <div class="movie-thumb c-thumb">
                          <a href="movie-details.html" class="w-100 bg_img h-100" data-background="./assets/images/movie/movie11.jpg">
                            <img class="d-sm-none" src="./assets/images/movie/movie11.jpg" alt="movie" />
                          </a>
                        </div>
                        <div class="movie-content bg-one">
                          <h5 class="title">
                            <a href="movie-details.html">halloween party</a>
                          </h5>
                          <p class="duration">2hrs 50 min</p>
                          <div class="movie-tags">
                            <a href="#0">action</a>
                            <a href="#0">adventure</a>
                            <a href="#0">fantasy</a>
                          </div>
                          <div class="release">
                            <span>Release Date : </span> <a href="#0"> November 8 , 2020</a>
                          </div>
                          <ul class="movie-rating-percent">
                            <li>
                              <div class="thumb">
                                <img src="./assets/images/movie/tomato.png" alt="movie" />
                              </div>
                              <span class="content">88%</span>
                            </li>
                            <li>
                              <div class="thumb">
                                <img src="./assets/images/movie/cake.png" alt="movie" />
                              </div>
                              <span class="content">88%</span>
                            </li>
                          </ul>
                          <div class="book-area">
                            <div class="book-ticket">
                              <div class="react-item">
                                <a href="#0">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/heart.png" alt="icons" />
                                  </div>
                                </a>
                              </div>
                              <div class="react-item mr-auto">
                                <a href="#0">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/book.png" alt="icons" />
                                  </div>
                                  <span>book ticket</span>
                                </a>
                              </div>
                              <div class="react-item">
                                <a href="#0" class="popup-video">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/play-button.png" alt="icons" />
                                  </div>
                                  <span>watch trailer</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="movie-list">
                        <div class="movie-thumb c-thumb">
                          <a href="movie-details.html" class="w-100 bg_img h-100" data-background="./assets/images/movie/movie12.jpg">
                            <img class="d-sm-none" src="./assets/images/movie/movie12.jpg" alt="movie" />
                          </a>
                        </div>
                        <div class="movie-content bg-one">
                          <h5 class="title">
                            <a href="movie-details.html">the most wanted</a>
                          </h5>
                          <p class="duration">2hrs 50 min</p>
                          <div class="movie-tags">
                            <a href="#0">action</a>
                            <a href="#0">adventure</a>
                            <a href="#0">fantasy</a>
                          </div>
                          <div class="release">
                            <span>Release Date : </span> <a href="#0"> November 8 , 2020</a>
                          </div>
                          <ul class="movie-rating-percent">
                            <li>
                              <div class="thumb">
                                <img src="./assets/images/movie/tomato.png" alt="movie" />
                              </div>
                              <span class="content">88%</span>
                            </li>
                            <li>
                              <div class="thumb">
                                <img src="./assets/images/movie/cake.png" alt="movie" />
                              </div>
                              <span class="content">88%</span>
                            </li>
                          </ul>
                          <div class="book-area">
                            <div class="book-ticket">
                              <div class="react-item">
                                <a href="#0">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/heart.png" alt="icons" />
                                  </div>
                                </a>
                              </div>
                              <div class="react-item mr-auto">
                                <a href="#0">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/book.png" alt="icons" />
                                  </div>
                                  <span>book ticket</span>
                                </a>
                              </div>
                              <div class="react-item">
                                <a href="#0" class="popup-video">
                                  <div class="thumb">
                                    <img src="./assets/images/icons/play-button.png" alt="icons" />
                                  </div>
                                  <span>watch trailer</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pagination-area text-center">
                  <a href="#0"><i class="fas fa-angle-double-left"></i><span>Prev</span></a>
                  <a href="#0">1</a>
                  <a href="#0">2</a>
                  <a href="#0" class="active">3</a>
                  <a href="#0">4</a>
                  <a href="#0">5</a>
                  <a href="#0"><span>Next</span><i class="fas fa-angle-double-right"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Helmet>


        <script src="./../assets/js/jquery-3.3.1.min.js"></script>
        <script src="./../assets/js/modernizr-3.6.0.min.js"></script>
        <script src="./../assets/js/plugins.js"></script>
        <script src="./../assets/js/bootstrap.min.js"></script>
        <script src="./../assets/js/heandline.js"></script>
        <script src="./../assets/js/isotope.pkgd.min.js"></script>
        <script src="./../assets/js/magnific-popup.min.js"></script>
        <script src="./../assets/js/owl.carousel.min.js"></script>
        <script src="./../assets/js/wow.min.js"></script>
        <script src="./../assets/js/countdown.min.js"></script>
        <script src="./../assets/js/odometer.min.js"></script>
        <script src="./../assets/js/viewport.jquery.js"></script>
        <script src="./../assets/js/nice-select.js"></script>
        <script src="./../assets/js/main.js"></script>
      </Helmet>
    </div >
  )
}

export default Movie