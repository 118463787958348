import React, { useState, useEffect } from 'react';
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import common_url from '../../http_common';
function EventCheckout() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [data, setData] = useState('');


  const { state } = useLocation();
  const eventBookingId = state._id;
  const amount = state.amount;
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  var options = {
    "key": "rzp_test_vcN8VSePA0MiTt", // Enter the Key ID generated from the Dashboard
    "amount": amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    "currency": "INR",
    "name": "acme",
    "description": "Test Transaction",
    "image": "https://example.com/your_logo",
    "order_id": data.orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    "handler": function (response) {
      // alert(response.razorpay_payment_id);
      // alert(response.razorpay_order_id);
      // alert(response.razorpay_signature);
      // nav('/confirm_delivery')
    }, "prefill": {
      "name": data.name,
      "email": data.email,
      "contact": data.contact,
    },
    "notes": {
      // "address": data.address
    },
    "theme": {
      "color": "#3399cc"
    }
  };
  // var rzp1 = new Razorpay(options);
  var rzp1 = new window.Razorpay(options);
  rzp1.on('payment.success', function (response) {
    // alert(response.error.code);
    // alert(response.error.description);
    // alert(response.error.source);
    // alert(response.error.step);
    // alert(response.error.reason);
    // alert(response.error.metadata.order_id);
    // alert(response.error.metadata.payment_id);
    // nav('/confirm_delivery')
  });

  async function RazorPayIntegration(e) {
    rzp1.open();
    e.preventDefault();
    // nav('/confirm_delivery')

  }
  async function order(e) {
    e.preventDefault();
    const token = localStorage.getItem('token');
    let payload = { name, email, contact, amount, eventBookingId }

      let result = await fetch(common_url+'event/createOrderIdForEvent',
        {
          method: "POST",
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        })
      const res = await result.json()
      setData(res.data)
      if (result.status == 200) {
        RazorPayIntegration(e);
      }

  

  }


  return (
    <div>
      <Header />
      <section class="details-banner event-details-banner hero-area bg_img seat-plan-banner" data-background="./assets/images/banner/banner07.jpg">
        <div class="container">
          <div class="details-banner-wrapper">
            <div class="details-banner-content style-two">
              <h3 class="title"><span class="d-block">Digital Marketing</span>
                <span class="d-block">Conference -2020</span></h3>
              <div class="tags">
                <span>17 South Sherman Street Astoria, NY 11106</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="page-title bg-one">
        <div class="container">
          <div class="page-title-area">
            <div class="item md-order-1">
              <a class="custom-button back-button">
                <i class="flaticon-double-right-arrows-angles"></i>back
              </a>
            </div>
            <div class="item date-item">
              <span class="date">MON, SEP 09 2020</span>
            </div>
            <div class="item">
              <h5 class="title">05:00</h5>
              <p>Mins Left</p>
            </div>
          </div>
        </div>
      </section>
      <div class="event-facility padding-bottom padding-top">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              {/* <div class="checkout-widget d-flex flex-wrap align-items-center justify-cotent-between">
                <div class="title-area">
                  <h5 class="title">Already a Boleto  Member?</h5>
                  <p>Sign in to earn points and make booking easier!</p>
                </div>
                <a href="#0" class="sign-in-area">
                  <i class="fas fa-user"></i><span>Sign in</span>
                </a>
              </div> */}
              <div class="checkout-widget checkout-contact">
                <h5 class="title">Share your Contact  Details </h5>
                <form class="checkout-contact-form">
                  <div class="form-group">
                    <input type="text" placeholder="Full Name" onChange={(e) => setName(e.target.value)} />
                  </div>
                  <div class="form-group">
                    <input type="text" placeholder="Enter your Mail" onChange={(e) => setEmail(e.target.value)} />
                  </div>
                  <div class="form-group">
                    <input type="text" placeholder="Enter your Phone Number " onChange={(e) => setContact(e.target.value)} />
                  </div>
                  <div class="form-group">
                    {/* <input type="submit" value="Continue" class="custom-button" onClick={(e) => order(e)}/> */}
                    <button class="btn custom-button" type="button" onClick={(e) => {
                      order(e)
                    }}>Continue</button>
                  </div>
                </form>
              </div>
              {/* <div class="checkout-widget checkout-contact">
                <h5 class="title">Get Your Tickets</h5>
                <div class="ticket--area row justify-content-center">
                  <div class="col-sm-6 col-md-4">
                    <div class="ticket-item">
                      <div class="ticket-thumb">
                        <img src="./assets/images/event/ticket/ticket01.png" alt="event"/>
                      </div>
                      <div class="ticket-content">
                        <span class="ticket-title">Standard Ticket</span>
                        <h2 class="amount"><sup>$</sup>49</h2>
                        <a href="#0" class="t-button">
                          <i class="fas fa-plus"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-4">
                    <div class="ticket-item two">
                      <div class="hot">
                        <span>hot</span>
                      </div>
                      <div class="ticket-thumb">
                        <img src="./assets/images/event/ticket/ticket02.png" alt="event"/>
                      </div>
                      <div class="ticket-content">
                        <span class="ticket-title">Premium Ticket</span>
                        <h2 class="amount"><sup>$</sup>79</h2>
                        <a href="#0" class="t-button">
                          <i class="fas fa-check"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-4">
                    <div class="ticket-item three">
                      <div class="ticket-thumb">
                        <img src="./assets/images/event/ticket/ticket03.png" alt="event"/>
                      </div>
                      <div class="ticket-content">
                        <span class="ticket-title">VIP Ticket</span>
                        <h2 class="amount"><sup>$</sup>99</h2>
                        <a href="#0" class="t-button">
                          <i class="fas fa-plus"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-30-none">
                  <div class="col-md-6 col-xl-5">
                    <form class="cart-button event-cart">
                      <span class="d-inline-block">Number of Seats : </span>
                      <div class="cart-plus-minus"><div class="dec qtybutton">-</div>
                        <input class="cart-plus-minus-box" type="text" name="qtybutton" value="2"/>
                          <div class="inc qtybutton">+</div></div>
                    </form>
                  </div>
                  <div class="col-md-6 col-xl-7">
                    <form class="checkout-contact-form mb-0">
                      <div class="form-group">
                        <input type="text" placeholder="Please enter promo code"/>
                      </div>
                      <div class="form-group">
                        <input type="submit" value="Verify" class="custom-button"/>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="checkout-widget checkout-card mb-0">
                <h5 class="title">Payment Option </h5>
                <ul class="payment-option">
                  <li class="active">
                    <a href="#0">
                      <img src="./assets/images/payment/card.png" alt="payment"/>
                        <span>Credit Card</span>
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <img src="./assets/images/payment/card.png" alt="payment"/>
                        <span>Debit Card</span>
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <img src="./assets/images/payment/paypal.png" alt="payment"/>
                        <span>paypal</span>
                    </a>
                  </li>
                </ul>
                <h6 class="subtitle">Enter Your Card Details </h6>
                <form class="payment-card-form">
                  <div class="form-group w-100">
                    <label for="card1">Card Details</label>
                    <input type="text" id="card1"/>
                      <div class="right-icon">
                        <i class="flaticon-lock"></i>
                      </div>
                  </div>
                  <div class="form-group w-100">
                    <label for="card2"> Name on the Card</label>
                    <input type="text" id="card2"/>
                  </div>
                  <div class="form-group">
                    <label for="card3">Expiration</label>
                    <input type="text" id="card3" placeholder="MM/YY"/>
                  </div>
                  <div class="form-group">
                    <label for="card4">CVV</label>
                    <input type="text" id="card4" placeholder="CVV"/>
                  </div>
                  <div class="form-group check-group">
                    <input id="card5" type="checkbox" checked/>
                      <label for="card5">
                        <span class="title">QuickPay</span>
                        <span class="info">Save this card information to my Boleto  account and make faster payments.</span>
                      </label>
                  </div>
                  <div class="form-group">
                    <input type="submit" class="custom-button" value="make payment"/>
                  </div>
                </form>
                <p class="notice">
                  By Clicking "Make Payment" you agree to the <a href="#0">terms and conditions</a>
                </p>
              </div> */}
            </div>
            {/* <div class="col-lg-4">
              <div class="booking-summery bg-one">
                <h4 class="title">booking summery</h4>
                <ul>
                  <li>
                    <h6 class="subtitle">Venus</h6>
                    <span class="info">English-2d</span>
                  </li>
                  <li>
                    <h6 class="subtitle"><span>City Walk</span><span>02</span></h6>
                    <div class="info"><span>10 SEP TUE, 11:00 PM</span> <span>Tickets</span></div>
                  </li>
                  <li>
                    <h6 class="subtitle mb-0"><span>Tickets  Price</span><span>$150</span></h6>
                  </li>
                </ul>
                <ul class="side-shape">
                  <li>
                    <h6 class="subtitle"><span>combos</span><span>$57</span></h6>
                    <span class="info"><span>2 Nachos Combo</span></span>
                  </li>
                  <li>
                    <h6 class="subtitle"><span>food & bevarage</span></h6>
                  </li>
                </ul>
                <ul>
                  <li>
                    <span class="info"><span>price</span><span>$207</span></span>
                    <span class="info"><span>vat</span><span>$15</span></span>
                  </li>
                </ul>
              </div>
              <div class="proceed-area  text-center">
                <h6 class="subtitle"><span>Amount Payable</span><span>$222</span></h6>
                <a href="#0" class="custom-button back-button">proceed</a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default EventCheckout