

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import logo from "../../assets/images/logo/logo.png";
import swal from 'sweetalert';
import common_url from '../../http_common';

import { useNavigate } from 'react-router-dom';


function AddTheatre() {

    const [password, setPassword] = useState();
    const [email, setEmail] = useState();
    const [name, setName] = useState();
    const [theatreName, setTheatreName] = useState();
    const [theatreAddress, setTheatreAddress] = useState();
    const [contact, setContact] = useState();
    const [location, setLocation] = useState();

    const nav = useNavigate()



    const AddTheatre = async (e) => {
        e.preventDefault();
        let payload = { email, password, name, theatreName, theatreAddress, contact, location }
        let resp = await fetch(common_url+'admin/registerForAdmin',
            {
                method: 'POST',
                headers: {
                    'Content-type': 'application/JSON'

                },
                body: JSON.stringify(payload)
            })

        const statuscode = resp.status
        resp = await resp.json()
        console.log(resp, 'res')

        if (statuscode === 200 || statuscode === 204 || statuscode === 201) {
            swal({
                title: "success!",
                text: resp.message,
                icon: "success",
                button: "ok!",
            });
            // window.location.reload(false);
            nav('/')
        }
        else if (statuscode === 401) {
            swal({
                title: "Error !",
                text: resp.message,
                icon: "success",
                button: "ok!",
            });
        }
        else {
            swal({
                title: "Fail!",
                text: resp.message,
                icon: "success",
                button: "ok!",
            });
        }

    }

    return (
        <div>
            <section class="account-section bg_img" data-background="./assets/images/account-bg.jpg">
                <div class="container">
                    <div class="padding-top padding-bottom">
                        <div class="account-area">
                            <div class="section-header-3">
                                <span class="cate">welcome</span>
                                <h2 class="title"><img src={logo} style={{ width: '184px' }} /></h2>
                            </div>
                            <form class="account-form">
                                <div class="form-group">
                                    <label for="name">Name<span>*</span></label>
                                    <input type="text" placeholder="Enter Your Name" id="name" onChange={(e) => setName(e.target.value)} required />
                                </div>
                                <div class="form-group">
                                    <label for="email1">Email<span>*</span></label>
                                    <input type="text" placeholder="Enter Your Email" id="email1" onChange={(e) => setEmail(e.target.value)} required />
                                </div>
                                <div class="form-group">
                                    <label for="pass1">Password<span>*</span></label>
                                    <input type="password" placeholder="Password" id="pass1" onChange={(e) => setPassword(e.target.value)} required />
                                </div>

                                <div class="form-group">
                                    <label for="name">Theatre Name<span>*</span></label>
                                    <input type="text" placeholder="Enter Theatre Name" id="theatrename" onChange={(e) => setTheatreName(e.target.value)} required />
                                </div> 
                                <div class="form-group">
                                    <label for="name">Theatre Address<span>*</span></label>
                                    <input type="text" placeholder="Enter Theatre Address" id="theatreaddress" onChange={(e) => setTheatreAddress(e.target.value)} required />
                                </div>
                                 <div class="form-group">
                                    <label for="name">Contact<span>*</span></label>
                                    <input type="text" placeholder="Enter Contact" id="contact" onChange={(e) => setContact(e.target.value)} required />
                                </div>
                                 <div class="form-group">
                                    <label for="name">Loction<span>*</span></label>
                                    <input type="text" placeholder="Enter Loction" id="location" onChange={(e) => setLocation(e.target.value)} required />
                                </div>
                                <div className="form-group text-center">
                                    <button class="btn btn-primary" type="button" onClick={(e) => {
                                        AddTheatre(e)
                                    }}>Add Theatre</button>
                                </div>
                            </form>
                          
                        </div>
                    </div>
                </div>
            </section>
            <Helmet>
                <script src="../../assets/js/jquery-3.3.1.min.js"></script>
                <script src="../../assets/js/modernizr-3.6.0.min.js"></script>
                <script src="../../assets/js/plugins.js"></script>
                <script src="../../assets/js/bootstrap.min.js"></script>
                <script src="../../assets/js/heandline.js"></script>
                <script src="../../assets/js/isotope.pkgd.min.js"></script>
                <script src="../../assets/js/magnific-popup.min.js"></script>
                <script src="../../assets/js/owl.carousel.min.js"></script>
                <script src="../../assets/js/wow.min.js"></script>
                <script src="../../assets/js/countdown.min.js"></script>
                <script src="../../assets/js/odometer.min.js"></script>
                <script src="../../assets/js/viewport.jquery.js"></script>
                <script src="../../assets/js/nice-select.js"></script>
                <script src="../../assets/js/main.js"></script>
            </Helmet>
        </div>
    )
}

export default AddTheatre