import React, { useState, useEffect } from 'react';
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import swal from 'sweetalert';
import common_url from '../../http_common';


function Contact() {

  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const ContactUs = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    let payload = { email, name, subject, message }
    let resp = await fetch(common_url+'blog/createContact ',
      {
        method: 'POST',
        headers: {
          'Authorization': token,

          'Content-type': 'application/JSON'

        },
        body: JSON.stringify(payload)
      })

    const statuscode = resp.status
    resp = await resp.json()
    console.log(resp, 'res')

    if (statuscode === 200 || statuscode === 204 || statuscode === 201) {
      swal({
        title: "success!",
        text: resp.message,
        icon: "success",
        button: "ok!",
      });
    }
    else if (statuscode === 401) {
      swal({
        title: "success!",
        text: resp.message,
        icon: "success",
        button: "ok!",
      });
    }
    else {
      swal({
        title: "success!",
        text: resp.message,
        icon: "success",
        button: "ok!",
      });
    }

  }
  return (
    <div>
      <Header />
      <div>
        <section class="main-page-header speaker-banner bg_img" data-background="./assets/images/banner/banner07.jpg">
          <div class="container">
            <div class="speaker-banner-content">
              <h2 class="title">contact us</h2>
              <ul class="breadcrumb">
                <li>
                  <a href="index.html">
                    Home
                  </a>
                </li>
                <li>
                  contact us
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section class="contact-section padding-top">
          <div class="contact-container">
            <div class="bg-thumb bg_img" data-background="./assets/images/contact.jpg"></div>
            <div class="container">
              <div class="row justify-content-between">
                <div class="col-md-7 col-lg-6 col-xl-5">
                  <div class="section-header-3 left-style">
                    <span class="cate">contact us</span>
                    <h2 class="title">get in touch</h2>
                    <p>We’d love to talk about how we can work together. Send us a message below and we’ll respond as soon as possible.</p>
                  </div>
                  <form class="contact-form" id="contact_form_submit">
                    <div class="form-group">
                      <label for="name">Name <span>*</span></label>
                      <input type="text" placeholder="Enter Your Full Name" name="name" id="name" required onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div class="form-group">
                      <label for="email">Email <span>*</span></label>
                      <input type="text" placeholder="Enter Your Email" name="email" id="email" required onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div class="form-group">
                      <label for="subject">Subject <span>*</span></label>
                      <input type="text" placeholder="Enter Your Subject" name="subject" id="subject" required onChange={(e) => setSubject(e.target.value)} />
                    </div>
                    <div class="form-group">
                      <label for="message">Message <span>*</span></label>
                      <textarea name="message" id="message" placeholder="Enter Your Message" required onChange={(e) => setMessage(e.target.value)} ></textarea>
                    </div>
                    <div class="form-group">
                      {/* <input type="submit" value="Send Message" /> */}
                      <button class="btn btn-primary" type="button" onClick={(e) => {
                        ContactUs(e)
                      }}>Send Message</button>
                    </div>
                  </form>
                </div>
                <div class="col-md-5 col-lg-6">
                  <div class="padding-top padding-bottom contact-info">
                    <div class="info-area">
                      <div class="info-item">
                        <div class="info-thumb">
                          <img src="./assets/images/contact/contact01.png" alt="contact" />
                        </div>
                        <div class="info-content">
                          <h6 class="title">phone number</h6>
                          <a href="Tel:82828282034">+1234 56789</a>
                        </div>
                      </div>
                      <div class="info-item">
                        <div class="info-thumb">
                          <img src="./assets/images/contact/contact02.png" alt="contact" />
                        </div>
                        <div class="info-content">
                          <h6 class="title">Email</h6>
                          <a href="Mailto:info@gmail.com"><span class="__cf_email__" data-cfemail="cfa6a1a9a08f8da0a3aabba0">[email&#160;protected]</span> .com</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="contact-counter padding-top padding-bottom">
          <div class="container">
            <div class="row justify-content-center mb-30-none">
              <div class="col-sm-6 col-md-3">
                <div class="contact-counter-item">
                  <div class="contact-counter-thumb">
                    <i class="fab fa-facebook-f"></i>
                  </div>
                  <div class="contact-counter-content">
                    <div class="counter-item">
                      <h5 class="title odometer" data-odometer-final="130">0</h5>
                      <h5 class="title">k</h5>
                    </div>
                    <p>Followers</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="contact-counter-item active">
                  <div class="contact-counter-thumb">
                    <i class="fas fa-users"></i>
                  </div>
                  <div class="contact-counter-content">
                    <div class="counter-item">
                      <h5 class="title odometer" data-odometer-final="35">0</h5>
                      <h5 class="title">k</h5>
                    </div>
                    <p>Members</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="contact-counter-item">
                  <div class="contact-counter-thumb">
                    <i class="fab fa-twitter"></i>
                  </div>
                  <div class="contact-counter-content">
                    <div class="counter-item">
                      <h5 class="title odometer" data-odometer-final="47">0</h5>
                      <h5 class="title">k</h5>
                    </div>
                    <p>Followers</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="contact-counter-item">
                  <div class="contact-counter-thumb">
                    <i class="fas fa-envelope"></i>
                  </div>
                  <div class="contact-counter-content">
                    <div class="counter-item">
                      <h5 class="title odometer" data-odometer-final="291">0</h5>
                      <h5 class="title">k</h5>
                    </div>
                    <p>Subscribers</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />

    </div>
  )
}

export default Contact