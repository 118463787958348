import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { signIn } from '../../action/auth';
import common_url from '../../http_common';



function SignIn() {

    const [password, setPassword] = useState();
    const [email, setEmail] = useState();
    const nav = useNavigate()
    // const dispatch = useDispatch();

    const login = async (e) => {
        let payload = { email, password }
        // dispatch(signIn(payload));
        let resp = await fetch(common_url+'user/loginForUser',
            {
                method: 'POST',
                headers: {
                    'Content-type': 'application/JSON'

                },
                body: JSON.stringify(payload)
            })

        const statuscode = resp.status
        resp = await resp.json()
        const token = resp.token
        localStorage.setItem('token', token)
        if (statuscode === 200 || statuscode === 204 || statuscode === 201) {
            swal({
                title: "success!",
                text: resp.message,
                icon: "success",
                button: "ok!",
              });
            nav("/")
            // window.location.reload(false);
        }
        // else if (statuscode === 401) {
        //     toast.success('Enter Registered Credentials', {
        //         position: "bottom-center",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //     });
        // }
        // else {
        //     toast.error('Something WEnt Wrong.Try again ...', {
        //         position: "bottom-center",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //     });
        // }

    }


    return (
        <div>
            {/* <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            /> */}
            <section class="account-section bg_img" style={{
                backgroundImage: "url(" + "../../assets/images/account-bg.jpg" + ")",

            }}>
                <div class="container">
                    <div class="padding-top padding-bottom">
                        <div class="account-area">
                            <div class="section-header-3">
                                <span class="cate">hello</span>
                                <h2 class="title">welcome back</h2>
                            </div>
                            <form class="account-form">
                                <div class="form-group">
                                    <label for="email2">Email<span>*</span></label>
                                    <input type="text" placeholder="Enter Your Email" id="email2" onChange={(e) => setEmail(e.target.value)} required />
                                </div>
                                <div class="form-group">
                                    <label for="pass3">Password<span>*</span></label>
                                    <input type="password" placeholder="Password" id="pass3" onChange={(e) => setPassword(e.target.value)} required />
                                </div>
                                <div class="form-group checkgroup">
                                    <input type="checkbox" id="bal2" required checked />
                                    <label for="bal2">remember password</label>
                                    <a href="" class="forget-pass">Forget Password</a>
                                </div>
                                {/* <div class="form-group text-center">
                                    <input type="submit" value="log in" />
                                </div> */}
                                <div className="form-group text-center">
                                    <button class="btn btn-primary" type="button" onClick={(e) => {
                                        login(e)
                                    }}>Login</button>
                                </div>
                            </form>
                            <div class="option">
                                Don't have an account? <a href="signUp">sign up now</a>
                            </div>
                            <div class="or"><span>Or</span></div>
                            <ul class="social-icons">
                                <li>
                                    <a href="#0">
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#0" class="active">
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#0">
                                        <i class="fab fa-google"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <Helmet>
                <script src="../../assets/js/jquery-3.3.1.min.js"></script>
                <script src="../../assets/js/modernizr-3.6.0.min.js"></script>
                <script src="../../assets/js/plugins.js"></script>
                <script src="../../assets/js/bootstrap.min.js"></script>
                <script src="../../assets/js/heandline.js"></script>
                <script src="../../assets/js/isotope.pkgd.min.js"></script>
                <script src="../../assets/js/magnific-popup.min.js"></script>
                <script src="../../assets/js/owl.carousel.min.js"></script>
                <script src="../../assets/js/wow.min.js"></script>
                <script src="../../assets/js/countdown.min.js"></script>
                <script src="../../assets/js/odometer.min.js"></script>
                <script src="../../assets/js/viewport.jquery.js"></script>
                <script src="../../assets/js/nice-select.js"></script>
                <script src="../../assets/js/main.js"></script>
            </Helmet>
        </div>
    )
}

export default SignIn