import React from 'react';
import ReactDOM from 'react-dom/client';
// import { Provider } from 'react-redux';
// import { createStore } from 'redux'
import App from './App';
// import rootReducer from './reducers'
// const store = createStore(rootReducer)
import { useState, createContext, useContext } from "react";
// import {city} from "../"

const root = ReactDOM.createRoot(document.getElementById('root'));
const UserContext = createContext();
root.render(
  <React.StrictMode>
    {/* <UserContext.Provider value={city}> */}
    <App />
    {/* </UserContext.Provider> */}
  </React.StrictMode>
);
