import React, { useState, useEffect } from 'react';
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import common_url from '../../http_common';

function SportsTicket() {
  const nav = useNavigate()

  const { state } = useLocation();
  const [sportsDetails, setSportsDetails] = useState([]);
  const [sportsTicket, setSportsTicket] = useState([]);


  console.log(state, 'sportsstate')
  const id = state._id


  useEffect(() => {
    getSports();
  }, []);
  const getSports = async () => {
    await axios({
      'method': 'GET',
      'url': common_url+'sports/getSportsTicket/' + id,
    })
      .then(response => {
        console.log(response.data.data[0], 'sports')
        setSportsDetails(response.data.data[0].sportsDetails);
        setSportsTicket(response.data.data[0].sportsTicket);
      })
      .catch((error) => {
        alert(error)
      }
      )
  }
  async function order(e,TicketDetails) {
    e.preventDefault();
    const token = localStorage.getItem('token');
    console.log(token,'token')
    console.log(TicketDetails,'x')
    const amount = TicketDetails.ticketPrice;
  
    let payload = { sportsDetails, TicketDetails,amount }
    console.log(payload, 'pcart')
    if (token != undefined || token != null) {
  
        let result = await fetch(common_url+'sports/sportsBooking',
            {
                method: "POST",
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })
        const res = await result.json()
        console.log(res.data, 'checkout')
        const details=res.data;
        console.log(details,'sdfdsdfsdf')
  
        if (result.status == 200) {
            // console.log(totalPrice,'totalp')
            nav('/sportscheckout', { state: details })
        }
    }
  
  }
  

  return (
    <div>
      <Header />
      <section class="details-banner event-details-banner hero-area bg_img seat-plan-banner style-two" data-background="./assets/images/banner/banner10.jpg">
        <div class="container">
          <div class="details-banner-wrapper">
            <div class="details-banner-content style-two">
              <h3 class="title"><span class="d-block">{sportsDetails.sportsName}</span>
                {/* <span class="d-block">tournament</span> */}
              </h3>
              <div class="tags">
                <span>{sportsDetails.address}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="page-title bg-one">
        <div class="container">
          <div class="page-title-area">
            <div class="item md-order-1">
              <a href="#0" class="custom-button back-button">
                <i class="flaticon-double-right-arrows-angles"></i>back
              </a>
            </div>
            <div class="item date-item">
              <span class="date">{sportsDetails.sportsTime}</span>
            </div>
            <div class="item">
              <h5 class="title">05:00</h5>
              <p>Mins Left</p>
            </div>
          </div>
        </div>
      </section>
      <div class="event-facility padding-bottom padding-top">
        <div class="container"><div class="section-header-3">
          <span class="cate">simple pricing</span>
          <h2 class="title">make an appointment</h2>
          <p>{sportsDetails.description}</p>
        </div>
          <div class="row justify-content-center mb-30-none">
            {sportsTicket.map(TicketDetails =>
              <div class="col-sm-6 col-md-4">
                <div class="sports-ticket">
                  <span class="cate">{TicketDetails.ticketName}</span>
                  <h2 class="ticket-title"><sup>$</sup>{TicketDetails.ticketPrice}</h2>
                  <p>Total Seats: <span>{TicketDetails.TotalNoOfSeats}</span></p>
                  {localStorage.getItem('token')?
                  <a onClick={(e) => order(e,TicketDetails)} class="custom-button">Book Tickets</a>:
                  <a href='/signIn' class="custom-button">Book Tickets</a>
                  }
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default SportsTicket