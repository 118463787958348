import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./Components/Header/Header";
import Footer from './Components/Footer/Footer';
import Home from './Components/Home/Home';
import SignIn from './Components/SignIn/SignIn';
import SignUp from './Components/SignUp/SignUp';
import Movie from './Components/Movie/movie';
import MovieDetails from './Components/Movie/MovieDetails';
import MovieTicketPlan from './Components/Movie/MovieTicketPlan';
import Events from './Components/Events/Events';
import EventDetails from './Components/Events/EventDetails';
import EventCheckout from './Components/Events/EventCheckout';
import EventTicket from './Components/Events/EventTicket';
import Sports from './Components/sports/Sports';
import SportsDetails from './Components/sports/SportsDetails';
import SportsTicket from './Components/sports/SportsTicket';
import SportsCheckout from './Components/sports/SportsCheckout';
import AboutUs from './Components/AboutUs/AboutUs';
import Contact from './Components/Contact/Contact';
import Blog from './Components/Blog/Blog';
import BlogDetails from "./Components/Blog/BlogDetails";
import AddTheatre from "./Components/AddTheatre/AddTheatre";


function App() {
  return (
    <div >
      <>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Home />} />
          </Routes>
          <Routes>
            <Route exact path="/signIn" element={<SignIn />} />
          </Routes>
          <Routes>
            <Route exact path="/signUp" element={<SignUp />} />
          </Routes>
          <Routes>
            <Route exact path="/movie" element={<Movie />} />
          </Routes>
          <Routes>
            <Route exact path="/moviedetails" element={<MovieDetails />} />
          </Routes>
          <Routes>
            <Route exact path="/movieticketplan" element={<MovieTicketPlan />} />
          </Routes>
          <Routes>
            <Route exact path="/eventdetial" element={<EventDetails />} />
          </Routes>
          <Routes>
            <Route exact path="/eventChekout" element={<EventCheckout />} />
          </Routes>
          <Routes>
            <Route exact path="/events" element={<Events />} />
          </Routes> 
           <Routes>
            <Route exact path="/eventticket" element={<EventTicket />} />
          </Routes>
          <Routes>
            <Route exact path="/sports" element={<Sports />} />
          </Routes> 
           <Routes>
            <Route exact path="/sportsdetail" element={<SportsDetails />} />
          </Routes>
          <Routes>
            <Route exact path="/sportsticket" element={<SportsTicket />} />
          </Routes> 
           <Routes>
            <Route exact path="/sportscheckout" element={<SportsCheckout />} />
          </Routes>
          <Routes>
            <Route exact path="/aboutus" element={<AboutUs />} />
          </Routes> 
           <Routes>
            <Route exact path="/contact" element={<Contact />} />
          </Routes>
          <Routes>
            <Route exact path="/blog" element={<Blog />} />
          </Routes>
          <Routes>
            <Route exact path="/blogdetails" element={<BlogDetails />} />
          </Routes>
          <Routes>
            <Route exact path="/addtheatre" element={<AddTheatre />} />
          </Routes>
        </BrowserRouter>
      </>
    </div>

  );
}

export default App;
