import React from 'react';
import logo from "../../assets/images/logo/logo.png";
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';


function Header() {
  return (
    <div>

      <header class="header-section">
        <div class="container">
          <div class="header-wrapper">
            <div class="logo">
              <a href="/">
                <img src={logo} alt="logo" />
              </a>
            </div>
            <ul class="menu">
              <li>
                <NavLink to="/" activeClassName="active">Home</NavLink>
              </li>
              <li>
                <NavLink to="/movie" activeClassName="active">movies</NavLink>
              </li>
              <li>
                <NavLink to="/events" activeClassName="active">events</NavLink>

              </li>
              <li>
                <NavLink to="/sports" activeClassName="active">sports</NavLink>
              </li>
              <li>
                <NavLink to="/aboutus" activeClassName="active">About Us</NavLink>
              </li>
              <li>
                <NavLink to="/blog" activeClassName="active">blog</NavLink>
              </li>
              <li>
                <NavLink to="/contact" activeClassName="active">contact</NavLink>
              </li>
              <li >
                <NavLink to="/addtheatre" activeClassName="active">join us</NavLink>
              </li>
              <li class="header-button pr-0">
                {localStorage.getItem('token')?
                <>
                <NavLink to="" activeClassName="active">Profile</NavLink>
                </>:
                <NavLink to="/signIn">Get Started</NavLink>
                
  }
              </li>
            </ul>
            <div class="header-bar d-lg-none">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </header>
      <Helmet>
        <script src="../../assets/js/jquery-3.3.1.min.js"></script>
        <script src="../../assets/js/modernizr-3.6.0.min.js"></script>
        <script src="../../assets/js/plugins.js"></script>
        <script src="../../assets/js/bootstrap.min.js"></script>
        <script src="../../assets/js/heandline.js"></script>
        <script src="../../assets/js/isotope.pkgd.min.js"></script>
        <script src="../../assets/js/magnific-popup.min.js"></script>
        <script src="../../assets/js/owl.carousel.min.js"></script>
        <script src="../../assets/js/wow.min.js"></script>
        <script src="../../assets/js/countdown.min.js"></script>
        <script src="../../assets/js/odometer.min.js"></script>
        <script src="../../assets/js/viewport.jquery.js"></script>
        <script src="../../assets/js/nice-select.js"></script>
        <script src="../../assets/js/main.js"></script>
      </Helmet>
    </div>
  )
}

export default Header