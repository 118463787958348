import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import logo from "../../assets/images/logo/logo.png";
import swal from 'sweetalert';

import { useNavigate } from 'react-router-dom';
import common_url from '../../http_common';

function SignUp() {

    const [password, setPassword] = useState();
    const [email, setEmail] = useState();
    const [name, setName] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const nav = useNavigate()



    const register = async (e) => {
        e.preventDefault();
        let payload = { email, password, name, confirmPassword }
        let resp = await fetch(common_url+'user/registerForUser',
            {
                method: 'POST',
                headers: {
                    'Content-type': 'application/JSON'

                },
                body: JSON.stringify(payload)
            })

        const statuscode = resp.status
        resp = await resp.json()
        console.log(resp,'res')
       
        if (statuscode === 200 || statuscode === 204 || statuscode === 201) {
            swal({
                title: "success!",
                text: resp.message,
                icon: "success",
                button: "ok!",
              });
            nav("/signIn")
            // window.location.reload(false);
        }
        // else if (statuscode === 401) {
        //     toast.success('Enter Registered Credentials', {
        //         position: "bottom-center",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //     });
        // }
        // else {
        //     toast.error('Something WEnt Wrong.Try again ...', {
        //         position: "bottom-center",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //     });
        // }

    }

    return (
        <div>
            <section class="account-section bg_img" data-background="./assets/images/account-bg.jpg">
                <div class="container">
                    <div class="padding-top padding-bottom">
                        <div class="account-area">
                            <div class="section-header-3">
                                <span class="cate">welcome</span>
                                <h2 class="title"><img src={logo} style={{ width: '184px' }} /></h2>
                            </div>
                            <form class="account-form">
                                <div class="form-group">
                                    <label for="name">Name<span>*</span></label>
                                    <input type="text" placeholder="Enter Your Name" id="name" onChange={(e) => setName(e.target.value)} required />
                                </div>
                                <div class="form-group">
                                    <label for="email1">Email<span>*</span></label>
                                    <input type="text" placeholder="Enter Your Email" id="email1" onChange={(e) => setEmail(e.target.value)} required />
                                </div>
                                <div class="form-group">
                                    <label for="pass1">Password<span>*</span></label>
                                    <input type="password" placeholder="Password" id="pass1" onChange={(e) => setPassword(e.target.value)} required />
                                </div>
                                <div class="form-group">
                                    <label for="pass2">Confirm Password<span>*</span></label>
                                    <input type="password" placeholder="Password" id="pass2" onChange={(e) => setConfirmPassword(e.target.value)} required />
                                </div>
                                <div class="form-group checkgroup">
                                    <input type="checkbox" id="bal" required checked />
                                    <label for="bal">I agree to the <a href="#0">Terms, Privacy Policy</a> and <a
                                        href="#0">Fees</a></label>
                                </div>
                                {/* <div class="form-group text-center">
                                    <input type="submit" value="Sign Up"/>
                                </div> */}
                                <div className="form-group text-center">
                                    <button class="btn btn-primary" type="button" onClick={(e) => {
                                        register(e)
                                    }}>Register</button>
                                </div>
                            </form>
                            <div class="option">
                                Already have an account? <a href="signIn">Login</a>
                            </div>
                            <div class="or"><span>Or</span></div>
                            <ul class="social-icons">
                                <li>
                                    <a href="#0">
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#0" class="active">
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#0">
                                        <i class="fab fa-google"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <Helmet>
                <script src="../../assets/js/jquery-3.3.1.min.js"></script>
                <script src="../../assets/js/modernizr-3.6.0.min.js"></script>
                <script src="../../assets/js/plugins.js"></script>
                <script src="../../assets/js/bootstrap.min.js"></script>
                <script src="../../assets/js/heandline.js"></script>
                <script src="../../assets/js/isotope.pkgd.min.js"></script>
                <script src="../../assets/js/magnific-popup.min.js"></script>
                <script src="../../assets/js/owl.carousel.min.js"></script>
                <script src="../../assets/js/wow.min.js"></script>
                <script src="../../assets/js/countdown.min.js"></script>
                <script src="../../assets/js/odometer.min.js"></script>
                <script src="../../assets/js/viewport.jquery.js"></script>
                <script src="../../assets/js/nice-select.js"></script>
                <script src="../../assets/js/main.js"></script>
            </Helmet>
        </div>
    )
}

export default SignUp