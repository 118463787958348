import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import common_url from '../../http_common';


function EventDetails() {

  const { state } = useLocation();
  console.log(state,'state')
  const [events, setEvents] = useState([]);
  const nav = useNavigate()
  const id = state._id

  useEffect(() => {
    getEvents();
  }, []);
  const getEvents = async () => {
    await axios({
      'method': 'GET',
      'url': common_url+'event/getSingleEvent/' + id,
    })
      .then(response => {
        console.log(response.data.message, 'eve')
        setEvents(response.data.message[0]);
        // setCast(response.data.message.cast);
        // setCrew(response.data.message.crew);
        // setDimenstion(response.data.message.dimension);
        // setMovieType(response.data.message.typeOfMovie);
        // setLanguage(response.data.message.language);
        // setReview(response.data.message.review);
      })
      .catch((error) => {
        alert(error)
      }
      )
  }
  
  function eventDetails(e, x) {
    nav('/eventticket', { state: events })
  }
  console.log(events,'dsdfsfd')
  return (
    <div>
      <Header/>
      
  <section class="event-banner-section bg_img" data-background="./assets/images/banner/banner06.jpg">
    <div class="container">
        <div class="event-banner">
            <a href="https://www.youtube.com/embed/GT6-H4BRyqQ" class="video-popup">
                <span></span>
                <i class="flaticon-play-button"></i>
            </a>
        </div>
    </div>
</section>
<section class="event-book-search padding-top pt-lg-0">
    <div class="container">
        <div class="event-search bg_img" data-background="./assets/images/ticket/ticket-bg01.jpg">
            <div class="event-search-top">
                <div class="left">
                    <h3 class="title">{events.eventName}</h3>
                </div>
                <div class="right">
                    <ul class="countdown">
                        <li>
                            <h2><span class="days">00</span></h2>
                            <p class="days_text">days</p>
                        </li>
                        <li>
                            <h2><span class="hours">00</span></h2>
                            <p class="hours_text">hrs</p>
                        </li>
                        <li>
                            <h2><span class="minutes">00</span></h2>
                            <p class="minu_text">min</p>
                        </li>
                        <li>
                            <h2><span class="seconds">00</span></h2>
                            <p class="seco_text">sec</p>
                        </li>
                    </ul>
                    
                    <a  onClick={(e) => eventDetails(e, events)} class="custom-button">book tickets</a>
                </div>
            </div>
            <div class="event-search-bottom">
                <div class="contact-side">
                    <div class="item">
                        <div class="item-thumb">
                            <img src="./assets/images/event/icon/event-icon01.png" alt="event"/>
                        </div>
                        <div class="item-content">
                            <span class="up">{events.eventTime}</span>
                            {/* <span>70+ Workshops</span> */}
                        </div>
                    </div>
                    <div class="item">
                        <div class="item-thumb">
                            <img src="./assets/images/event/icon/event-icon02.png" alt="event"/>
                        </div>
                        <div class="item-content">
                            <span class="up">{events.address}</span>
                            {/* <span>Astoria, NY 11106</span> */}
                        </div>
                    </div>
                    {/* <div class="item">
                        <div class="item-thumb">
                            <img src="./assets/images/event/icon/event-icon03.png" alt="event"/>
                        </div>
                        <div class="item-content">
                            <span class="up">Drop us a line:</span>
                            <a href="MailTo:hello@Boleto .com"><span class="__cf_email__" data-cfemail="ee868b828281aeac81828b9a81">[email&#160;protected]</span> .com</a>
                        </div>
                    </div> */}
                </div>
                <ul class="social-icons">
                    <li>
                        <a href="#0">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#0" class="">
                            <i class="fab fa-twitter"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#0" class="active">
                            <i class="fab fa-pinterest-p"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#0" class="">
                            <i class="fab fa-google"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#0">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>    
<section class="event-about padding-top padding-bottom">
    <div class="container">
        <div class="row justify-content-between flex-wrap-reverse">
            <div class="col-lg-7 col-xl-6">
                <div class="event-about-content">
                    <div class="section-header-3 left-style m-0">
                        <span class="cate">are you ready to attend?</span>
                        <h2 class="title">{events.eventName}</h2>
                        <p>{events.description}  </p>
                        <a  onClick={(e) => eventDetails(e, events)} class="custom-button">book tickets</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-7">
                <div class="event-about-thumb">
                    <img src="./assets/images/event/event-about.jpg" alt="event"/>
                </div>
            </div>
        </div>
    </div>
</section>
      <Footer/>
    </div>
  )
}

export default EventDetails